<template>
    <ion-page>
        <CabeceraComponent :titulo="tipo == 'pipeline' ? 'Resumen Cartera de Proyectos' : 'Resumen de mi cartera'"
            :color="tipo === 'pipeline' ? 'primary' : 'darksuccess'" />
        <ion-content>
            <!-- TEST-->
            <ion-fab vertical="top" horizontal="end" slot="fixed" edge v-if="($tg == 1)">
                <ion-fab-button color="success" @click="JoinTelegram">
                    <!-- <ion-icon :md="assets/google.svg"></ion-icon>-->
                    <ion-icon icon="assets/Telegram_logo.svg" />
                </ion-fab-button>
            </ion-fab>

            <!-- Resumen del total-->
            <ion-grid size="48" size-sm="24" v-if="showme">
                <ion-toolbar class="separador" v-if="last_db_update">
                    <ion-title :class="tipo === 'pipeline' ? 'separador-todos' : 'separador-mios'">
                        Cartera de proyectos GES
                    </ion-title>
                    <ion-button style="float:right" color="darksuccess" @click="goSummary('yo')"
                        v-if="tipo === 'pipeline' && $rol === 10" size="small">Ir a mi Cartera</ion-button>
                    <ion-button style="float:right" color="primary" @click="goSummary('all')"
                        v-else-if="tipo !== 'pipeline' && $rol === 10" size="small">Vista Gerente</ion-button>
                </ion-toolbar>
                <!-- Total proyectos GES -->
                <ion-row class="tarjetas" padding-top padding-bottom margin-top margin-bottom v-if="datos.ges">
                    <TarjetaPipeline :detalle="$filters.Entero(datos.ges['ot'])"
                        :nota="$filters.Entero(datos.ges['vt'] / 1000) + ' k€'" titulo="Proyectos" v-if="datos.ges" />
                    <TarjetaPipeline :detalle="$filters.Entero(datos.ges['ct'])"
                        :nota="'de ' + $filters.Entero(datos.ges['cx'])" titulo="Clientes" v-if="datos.ges" />
                    <TarjetaPipeline :detalle="$filters.Entero(datos.ges['et'])"
                        :nota="'de ' + $filters.Entero(datos.ges['ex'])" titulo="Comerciales" v-if="datos.ges" />
                </ion-row>

                <ion-toolbar class="separador" v-if="last_db_update && !$ges && $utype != 'marcas'">
                    <ion-title :class="tipo === 'pipeline' ? 'separador-todos' : 'separador-mios'"
                        @click="go('cartera')">
                        Tu cartera
                        <span v-if="$rol == 10 && tipo == 'pipeline'"> como gerente </span>
                        <span v-else-if="$rol == 10 && tipo != 'pipeline'"> (tus clientes) </span>
                        <br />
                        <small>Totales y promedios por comecial vs GES</small>
                    </ion-title>
                </ion-toolbar>
                <!-- Total proyectos gestionados -->
                <ion-row class="tarjetas" padding-top padding-bottom margin-top margin-bottom
                    v-if="datos.mistotales && !$ges && $utype != 'marcas'">
                    <TarjetaPipelineKnob :detalle="$filters.Entero(datos.mistotales['ot'])"
                        :nota="$filters.Entero(datos.mistotales['vt'] / 1000) + ' k€'" :knob_data="benchmark.proyectos"
                        subnota="totales" titulo="Proyectos" v-if="datos.mistotales" />
                    <TarjetaPipelineKnob :detalle="$filters.Entero(datos.mistotales['ct'])"
                        :nota="'de ' + $filters.Entero(datos.mistotales['cx'])" :knob_data="benchmark.clientes"
                        subnota="clientes" titulo="Clientes" v-if="datos.mistotales" />
                    <TarjetaPipelineKnob :detalle="$filters.Entero(datos.mistotales['et'])"
                        :nota="`de ${$filters.Entero(datos.mistotales['ex'])}`" subnota="comerciales"
                        :knob_data="benchmark.comerciales" titulo="Comerciales" v-if="datos.mistotales" />
                </ion-row>
            </ion-grid>
            <!--Principales cambios por marca o pdv (según tu perfil)-->
            <ion-grid size="48" size-sm="24">
                <ion-toolbar class="separador" v-if="showme">
                    <ion-title :class="tipo === 'pipeline' ? 'separador-todos' : 'separador-mios'"
                        v-if="tops || $rol == 2">
                        Top contributors

                    </ion-title>
                    <ion-title class="separador-alerta" v-if="!tops && $rol != 2">
                        Por debajo del estándar</ion-title>
                    <ion-button style="float:right" color="darkdanger" size="small" @click="tops = !tops"
                        v-if="tops && $rol != 2 && tipo == 'pipeline' && $utype != 'marcas'">
                        Ver «últimos»
                    </ion-button>
                    <ion-button style="float:right" color="primary" size="small" @click="tops = !tops"
                        v-if="!tops && $rol != 2">
                        Ver Tops
                    </ion-button>
                </ion-toolbar>
                <ion-row class="tarjetas" v-if="showme && tops">
                    <!-- Enseñamos siempre las marcas, si es usuario mono pdv el limite es el menor entre 10 y las marcas existentes -->
                    <tarjeta-lista-pipeline :propiedad="version" clase="marcas" titulo="Marcas" :dat="datos.marcas_sum"
                        :limite="Math.min($multi == 'multi' ? limite_lista : datos.marcas_sum.length, limite_lista)"
                        :asc="true" size-lg="48" :size-sm="$multi == 'multi' ? 24 : 48">
                    </tarjeta-lista-pipeline>
                    <tarjeta-lista-pipeline :propiedad="version" clase="pdvs" titulo="Pdvs" :dat="datos.pdvs_sum"
                        :limite="limite_lista" :asc="true" size-lg="48" size-sm="24" v-if="$multi == 'multi'">
                    </tarjeta-lista-pipeline>
                </ion-row>
                <!-- Enseñamos siempre los exteriores (salvo que sea una petición de solo la vista de un exteriores)
                    si es usuario mono pdv el limite es el menor entre 10 y las marcas existentes -->
                <ion-row class="tarjetas" v-if="showme">
                    <tarjeta-lista-pipeline-grande titulo="Comerciales" :dat="datos.exts_sum" clase="exts"
                        :propiedad="version" :labels="exts_labels"
                        :limite="Math.min($multi == 'multi' ? limite_lista : datos.exts_sum.length, limite_lista)"
                        :asc="true" size="48" size-sm="24"
                        v-if="datos.exts_sum && $rol != 2 && tipo != 'mi_pipeline' && tops">
                    </tarjeta-lista-pipeline-grande>

                    <!-- Enseñamos siempre los plines, si es usuario mono pdv el limite es el menor entre 10 y las marcas existentes -->
                    <tarjeta-lista-pipeline-grande titulo="Product Line" :dat="datos.plines_sum" clase="plines"
                        :propiedad="version" :labels="plines_labels"
                        :limite="Math.min($multi == 'multi' ? limite_lista : datos.plines_sum.length, limite_lista)"
                        :asc="true" v-if="datos.plines_sum && tops" size="48" size-sm="24">
                    </tarjeta-lista-pipeline-grande>
                </ion-row>
                <!-- caso en que mostramos 'los peores'-->
                <ion-row class="tarjetas" v-if="showme && $utype != 'marcas'">
                    <tarjeta-lista-pipeline-grande titulo="Comerciales" :dat="datos.underperformer_ext" clase="exts"
                        :propiedad="version" :labels="exts_labels"
                        :limite="Math.min($multi=='multi' ? limite_lista : datos.underperformer_ext.length,limite_lista)"
                        :asc="true" size="48" size-sm="48"
                        v-if="datos.underperformer_ext && $rol != 2 && tipo != 'mi_pipeline' & !tops">
                    </tarjeta-lista-pipeline-grande>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage, IonGrid, IonContent, IonTitle, IonToolbar, IonRow, IonButton,
    IonFab, IonFabButton, IonIcon, alertController
} from '@ionic/vue';
import TarjetaPipeline from '@/components/pipeline/TarjetaPipeline'
import TarjetaPipelineKnob from '@/components/pipeline/TarjetaPipelineKnob'
import TarjetaListaPipeline from '@/components/pipeline/TarjetaListaPipeline'
import TarjetaListaPipelineGrande from '@/components/pipeline/TarjetaListaPipelineGrande'
import CabeceraComponent from '@/components/CabeceraComponent'
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { fetchPipeline } from '../../utilities/services/proyectos_summary_services'
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
export default defineComponent({
    name: "ResumenPipeline",
    components: {
        IonPage, IonGrid, IonContent, IonTitle, IonToolbar, IonRow, IonButton,
        IonFab, IonFabButton, IonIcon,
        CabeceraComponent,
        TarjetaPipeline, TarjetaPipelineKnob,
        TarjetaListaPipeline, TarjetaListaPipelineGrande
    },
    mounted() {
        window.fake_console('en el mount')
        this.init()
    },
    updated() {
        window.fake_console('En el update')
        this.init()
    },
    methods: {
        async init() {
            this.showme = false;
            const route = useRoute();
            this.tipo = route.params.tipo
            /*const act =  await Storage.get({ key: 'proyectos:last_update' });
            window.fake_console('ResumenPipeline ',  act)
            this.last_db_update = JSON.parse(act.value)*/
            const lbs = await Storage.get({ key: 'proyectos:labels:l' });
            this.plines_labels = JSON.parse(lbs.value)
            const sfData = await fetchPipeline(this.tipo)
            const lbse = await Storage.get({ key: 'proyectos:labels:e' });
            this.exts_labels = JSON.parse(lbse.value)
            window.fake_console('ResumenPipeline ', sfData)
            this.datos = sfData
            window.fake_console('ResumenPipeline ', this.datos.totals)

            const mpl = await this.$http.get('/marcas_pline')
            await Storage.set({ key: `proyectos:marcas_pline`, value: JSON.stringify(mpl.data) })

            /** Benchmark de proyectos promedio por comercial  */
            this.benchmark['proyectos'] = {
                'ges': parseInt(Math.round(this.datos.ges.ot / this.datos.ges.ex)),
                'yo': parseInt(Math.round(this.datos.mistotales.ot / this.datos.mistotales.ex)),
                'target': 10, 'desc': '/c'
            }
            this.benchmark['clientes'] = {
                'ges': parseInt(Math.round(this.datos.ges.ct * 100 / this.datos.ges.cx)),
                'yo': parseInt(Math.round(this.datos.mistotales.ct * 100 / this.datos.mistotales.cx)),
                'target': 10, 'desc': '%'
            }
            this.benchmark['comerciales'] = {
                'ges': parseInt(Math.round(this.datos.ges.et * 100 / this.datos.ges.ex)),
                'yo': parseInt(Math.round(this.datos.mistotales.et * 100 / this.datos.mistotales.ex)),
                'target': 90, 'desc': '%'
            }
            window.fake_console(this.benchmark['proyectos'])
            window.fake_console(this.benchmark['clientes'])
            window.fake_console(this.benchmark['comerciales'])
            this.showme = true;
        },
        goSummary(caso) {
            const url = caso == 'yo' ? '/resumen/mi_pipeline' : '/resumen/pipeline'
            this.$router.replace(url)
        },
        async JoinTelegram() {
            const self = this
            const alerta = await alertController
                .create({
                    header: 'Adherirse a Telegram',
                    subHeader: 'Unete al sistema de alertas internas de GES en Telegram',
                    message: 'Manda el mensaje «/start» a la cuenta @Proyectos_Ges_bot y sigue las instrucciones. Clickando «Si» abriremos Telegram e intentaremos ayudarte en el proceso.',
                    mode: "md",
                    buttons: [
                        {
                            text: 'No'
                        },
                        {
                            text: 'Si',
                            handler: async () => {
                                try {
                                    window.open('https://t.me/Proyectos_Ges_bot?start=12345');
                                    self.$router.go(-1)
                                }
                                catch (ex) {
                                    alert('No hemos podido borrar el proyecto')
                                }
                            },
                        },
                    ]
                })
            return alerta.present();
        },
        go() {
            const url = '/listado/pipeline'
            this.$router.replace(url)
        }
    },
    data() {
        return {
            showme: false,
            last_db_update: undefined,
            datos: undefined,
            version: 'valor',
            limite_lista: 10,
            plines_labels: undefined,
            exts_labels: undefined,
            benchmark: {},
            tipo: undefined,
            tops: true
        }
    },
})
</script>

<style scoped>
ion-icon {
    font-size: 4rem;
}

ion-title {
    width: 100% !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}

TarjetaPipeline {
    width: 20%;
}

ion-toolbar.separador {
    width: 96%;
    padding-top: 10px !important;
    margin-left: 2% !important;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--ion-color-light);
    --min-height: 36px !important;
}

ion-title.separador {
    padding-left: 16px;
    padding-right: 16px;
    width: 90% !important;
    text-align: left;
    color: var(--ion-color-primary);
}

ion-title.separador-todos {
    padding-left: 16px;
    padding-right: 16px;
    width: 90% !important;
    text-align: left;
    color: var(--ion-color-primary);
}

ion-title.separador-mios {
    padding-left: 16px;
    padding-right: 16px;
    width: 90% !important;
    text-align: left;
    color: var(--ion-color-darksuccess);
}

ion-title.separador-alerta {
    padding-left: 16px;
    padding-right: 16px;
    width: 90% !important;
    text-align: left;
    color: var(--ion-color-darkdanger);
}

ion-row.tarjetas {
    width: 96%;
    margin: auto;
}
</style>