 /**
     * Servicios para recuperar los datos resumen del proyectos DIARIO 
     * Son los que se utilizan en la parte 'proyectos'
  */

import { chain , sortBy, uniq, reduce, pluck} from 'underscore';
import axios from 'axios';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

    /** DATOS SINTECOS DE proyectos Y RANKINGS */
    const fetchPipeline =  async function(tipo) { 
        const url = tipo=='pipeline' ? '/pipeline_summary'  : '/mi_pipeline_summary'
        window.fake_console(url)
        const result =  await axios.get(url) 
        window.fake_console('proyectos_services ','Es cache?? : ',result.request.fromCache)
        let proyectosSummaryData = result.data
        window.fake_console(proyectosSummaryData)  
        /* 
            Resumen general  
            N/A, ya viene tanto para ges como para la persona con los datos 
        */ 
       window.fake_console(proyectosSummaryData)
        const detailData = proyectosSummaryData.details
        const ges = proyectosSummaryData.ges
        //const promedio_clientes_exterior = parseInt(ges.ct*100/ges.cx)
        //const promedio_proyectos_exterior = parseInt(ges.ot*100/ges.ex)
        const KPI = (ges.ot*ges.ct)*100/(ges.cx*ges.cx)*(ges.ex/ges.et)
        
        const mistotales = proyectosSummaryData.mistotales
        const resumen_pdvs_exteriores =reduce(proyectosSummaryData.org,(m,v) => {
            if( m[v['px']]==undefined) {m[v['px']] = {'ex': 0 ,  'cx' : 0}}
            m['ex']+=1 ; 
            m['cx']+= v['n'];
            m[v['e']] = v['n']
            return m            
        },{})
        
        /* Resumen marcas  */ 
        let marcas_sum = chain(detailData).reduce((m,v)=>{
            if (m[v['m']]==undefined){ m[v['m']]= {'_id': v['m'], 'vt' :v['vt'], 'ct' : v['ct'] ,'ot' : v['ot'] , 'cl':'marcas' }}
            else { m[v['m']]['vt']+=v['vt']; m[v['m']]['ot']+=v['ot'];m[v['m']]['ct']+=1}
            return m 
        },{}).values().sortBy((x)=>{return -x['vt']}).value()
        /* Resumen pdvs  */ 
         let pdvs_sum = chain(detailData).reduce((m,v)=>{
            if (m[v['px']]==undefined){ 
                m[v['px']]= {
                    '_id': v['px'], 'vt' :v['vt'], 'ct'  : v['ct'],'ot' : v['ot']  , 
                    'cl':'pdvs' , 'et':[v['e']]
                }
            }
            else { 
                m[v['px']]['vt']+=v['vt'];
                m[v['px']]['ot']+=v['ot']; 
                m[v['px']]['ct']+=1;
                m[v['px']]['et'].push(v['e'])
                m[v['px']]['et'] = uniq(m[v['px']]['et'])
            }
            return m 
        },{}).values().map(y=>{y['et']= y['et'].length; return y}).sortBy((x)=>{return -x['vt']}).value()
        /* Resumen product_lines  */ 
        let plines_sum = chain(detailData).reduce((m,v)=>{
            if (m[v['l']]==undefined){ m[v['l']]= {'_id': v['l'], 'vt' :v['vt'], 'ct'  : v['ct'],'ot' : v['ot']  , 'cl':'plines' }}
            else { m[v['l']]['vt']+=v['vt']; m[v['l']]['ot']+=v['ot']; m[v['l']]['ct']+=1}
            return m 
        },{}).values().sortBy((x)=>{return -x['vt']}).value()
        /* Resumen exteriores  */ 
        let exts_sum = chain(detailData).reduce((m,v)=>{
            if (m[v['e']]==undefined){ m[v['e']]= {'_id': v['e'], 'vt' :v['vt'], 'ct'  : v['ct'],'ot' : v['ot']  , 'cl':'exts' }}
            else { m[v['e']]['vt']+=v['vt'];m[v['e']]['ot']+=v['ot']; m[v['e']]['ct']+=1}
            return m 
        },{}).values().sortBy((x)=>{return -x['vt']}).value()

        /** Vemos como estan en comparación con el conjunto (los peores de cada caso) */
        exts_sum.forEach(x=> { 
            /** Porcentaje de clientes con proyectos  */
            x['cx'] = resumen_pdvs_exteriores[x['_id']]
            x['rk'] = (x['ot']*x['ct']*100)/(resumen_pdvs_exteriores[x['_id']]*resumen_pdvs_exteriores[x['_id']])
            x['rank'] =x['rk']/KPI
            
        })
        window.fake_console(resumen_pdvs_exteriores)
        pdvs_sum.forEach(x=>{
            /*
            const cx = resumen_pdvs_exteriores[x['_id']]['cx']
            const ex = resumen_pdvs_exteriores[x['_id']]['ex']
            */
            const cx = resumen_pdvs_exteriores[x['_id']]==undefined ? 0 : resumen_pdvs_exteriores[x['_id']]['cx']
            const ex = resumen_pdvs_exteriores[x['_id']]==undefined ? 0 : resumen_pdvs_exteriores[x['_id']]['ex']
            x['rk']= x['ct']*x['ot']*100/(cx*ex)
            x['rank'] =x['rk']/KPI
                        
        })
       const underperformer_ext = sortBy(exts_sum, 'rank').filter((i)=>i.rank<1||i.ot<10)
       const underperformer_pdv = sortBy(pdvs_sum, 'rank').filter((i)=>i.rank<1||i.ot<=(9*i.ex))
       await Storage.set({ key: `proyectos:comerciales_activos`, value: JSON.stringify(pluck(exts_sum,'_id'))}); 
       console.log({underperformer_ext, underperformer_pdv}  )
       return { ges, mistotales, marcas_sum, pdvs_sum, plines_sum, exts_sum, underperformer_ext, underperformer_pdv}  
    } 


export {
    fetchPipeline
}
