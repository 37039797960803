/** Constantes globales utilizadas en el conjunto de la aplicación  */

/** Delay de actualización (datos de disco, no actualizamos desde server) */
const DELAY = 15000/ 900000 

/**
 * Propiedades asociadas a cada eje , es decir que campo de datos se ha de utilizar 
 * para filtrar en funcion de cada propiedad 
 */    

const propiedadEje = {
    'marcas': 'm', 'fabs': 'm', 
    'pdvs' :'e' , 'drvs' :'e' ,
    'plines' :'l', 'exts' :'e'
}

/**
 * Nombres asociados a los distintos ejes (como se presentan)
 */
const etiquetas = {
    'marcas' : 'Marcas', 'fabs' : 'Fabricantes', 
    'pdvs' : 'Pdvs', 'drvs' : 'Drvs',  
    'plines' : 'Product Lines','exts' : 'Comercial'
}


/** 
 * Nombres de las variables que vienen en la descarga de datos de sufficiency 
 */

const nombres_proyectos = {  
        "v":"Venta Estimada", 
        "m":"Marcas Posibles", 
        "mp":"Marca Principal",
        "ob" : "Obra / Proyecto" ,
        "bk" : "Partida", 
        "l" : "Product Line",   
        "nc" : "Nombre cliente", 
        "c" : "Codcli", 
        "p" : "Pdv", 
        "px" :"Pdv",
        "n" : "Nif",
        "np": "Nombre Pdv", 
        "e": "Exterior", 
        "ne" : "Comercial Exterior", 
        "nmp" : "Marca «Ganadora»", 
        "nm" : "Otras Marcas posibles", 
        "cn" : "Gran Cuenta",
        "i" : "Global", 
        "t": "Fecha Revision"
        }

    const nombres_campos = {
        "pipeline" :nombres_proyectos,  
        "mis_proyectos" :nombres_proyectos, 
        "to_expire" :nombres_proyectos
        }



    /** titulos de pagina en función del esquema de datos utilizado  */
    const titulos =  {
        'pipeline': 'Cartera de proyectos', 
        'mis_proyectos': 'Proyectos de mis clientes', 
        'to_expire' : 'Caducan en breve', 
        'new_projects': 'Nuevos proyectos (ult. 7 dias)'

    }
/** colores de headers y footers en función del esquema utilizado */

    const colores_titulos = {
        'pipeline': 'primary', 
        'mis_proyectos': 'darksuccess', 
        'to_expire' : 'danger', 
        'new_projects' : 'success', 
    }

/** 
 * Colores que aplicamos a los badge que muestran los valores seleccionados en los filtros 
 * Lo hacemos para poder distinguir que estan filtrando de forma visual, en especial para distinguir 
 * marcas y fabricantes que en muchos casos se llaman igual 
 */
    const colores_badges = {
        'marcas' : 'primary', 'fabs' : 'tertiary', 
        'pdvs' : 'primary', 'drvs' : 'secondary',  'exts' : 'tertiary'
    }


/**
 * Columnas que queremos que se vean en la tabla para los datos de sufficiency en función del tamaño de la pantalla 
 */

const columnas_proyectos = {
        'sm' : ['px','ob', 'nc','mp', 'v'], 
        'md' : ['px','ob', 'nc','mp', 'v'],  
        'lg' : ['px','ob', 'nc','ne','nmp', 'v'],  
        'xl' : ['np','ob', 'bk','nc','ne','l','nmp','nm',  'v']
    
}
    const columnas_tipo = {
        "pipeline": columnas_proyectos, 
        "mis_proyectos": columnas_proyectos
        }
  

/**
 * Variable que define la prioridad de cada filtro en caso de que no se puedan ver todos. 
 * Por ejemplo, si  la pantalla solo admite 2 filtros, 
 * cogerá los dos primeros (una vez excluidos los puntos de vista)
 * Los presentará siguiendo el orden que establezca la propiedad 'filters' 
 * en la constante filtros_activos ( ver esquema más adelanta siguiente ) para ese formato de vista
 */    
 
    const priorizacion_filtros = ['marcas','exts','pdvs','plines','drvs','fabs']


/**
 * Filtros máximos que podemos ver en función del tamaño de pantalla 
 * Es el número total de filtros (distintos del punto de vista) posibles  
 */
    const numberOfFilters = {'sm':2, 'md':2,'lg':8, 'xl':8}


/**
 * Opciones de filtros en función de cada formato de uso 
 *  single / multiple (solo 1 pdv en scope o varios)
 *  eje de entrada en el listado (pdvs, marcas o plines)
 */

    const filtros_activos =['pdvs','exts','marcas', 'plines','drvs','fabs','zonas' ] 
    
    
     

export {
    etiquetas, nombres_campos , columnas_tipo , colores_badges, 
    filtros_activos, numberOfFilters , priorizacion_filtros, propiedadEje, 
    titulos, colores_titulos, DELAY
}

