<template>
    <ion-header>
        <ion-toolbar  :color="color===undefined ? 'primary' : color " class="ion-justify-content-start ion-align-items-start"> 
            <ion-buttons slot="start" >
                <ion-menu-button />
            </ion-buttons >
           <ion-title>
                <ion-row  class="ion-justify-content-start ion-align-items-start">
                    <ion-col size="40"  class="ion-float-left izquierda">
                        {{titulo}}
                    </ion-col>
                </ion-row>                
            </ion-title>
        </ion-toolbar>
    </ion-header>
</template>

<script>
    import {    IonHeader, IonMenuButton, IonTitle, 
                IonToolbar,IonButtons,
                 IonCol, IonRow,
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "CabeceraComponent",
        components: {  IonHeader, IonMenuButton, IonTitle, 
        IonToolbar, IonButtons, 
        IonCol, IonRow },
        props : ['titulo', 'color'] 
    })
</script>

<style scoped>
    ion-title {
        width: 100% !important;
        padding-right: 4px;
    }
    ion-row {
        width: 100% !important; 
    }
    .izquierda {
        text-align:left; 
    }
</style>