<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" :color="color_pag"/>
        <ion-content class="ion-padding" >  

            <ion-row class="ion-justify-content-left ion-align-items-top selectores">
                    <ion-col  size="24" size-sm="12" >
                        <div class="p-input-icon-right">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 4 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                    </ion-col>
                        
                    <ion-col size="24" size-sm="12"   v-for = "(vf,idf,index) in filters" v-bind:key="device.tipo+idf" >
                        <!-- appenTo="etiquetas_ocultas" -->
                        <MultiSelect  v-model="SelectedFilters[idf]" :options="vf" optionLabel="k" 
                            :filter="true"   
                            v-bind:key="device.tipo+idf" 
                            @change="filtrar"
                            :placeholder="'Filtrar '+etiquetas[idf]" display="comma"
                            v-if="index<numFiltros"/>
                    </ion-col>

            </ion-row>

            <ion-row v-if="SelectedFilters">
                <div  v-for="(s) in Object.keys(SelectedFilters)" v-bind:key="s">
                    <ion-badge mode="ios" 
                        v-for="(zn,index) in SelectedFilters[s]" 
                        :color="colores_badges[s]" v-bind:key="index"
                        @click="eliminar(s,index)">
                        {{zn.k}}
                         <ion-icon :icon="closeCircle" class="badge"></ion-icon>
                    </ion-badge>
                </div>
                <!-- <div id="etiquetas_ocultas"></div>-->
            </ion-row>
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner :color="color_pag" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
                <DataTable :value="filtrados" :rows="filas" 
                    :paginator="paginador"
                    paginatorTemplate="RowsPerPageDropdown  FirstPageLink PrevPageLink  NextPageLink LastPageLink CurrentPageReport"
                    :rowsPerPageOptions="[10,25,50,100]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    v-if="!loading && device.tipo"
                    class="p-datatable-sufficiency" 
                    v-bind:key="device.tipo"
                    scrollHeight="flex"
                    scrollDirection="vertical" 
                    :scrollable="true" 
                    removableSort
                    ref="datatable"
                    :rowClass="WillExpire"
                    @row-click="ShowDetail" 
                     >
                     <template #header>
                        <div class="table-header">
                            <ion-col size="44" v-if="tipo_prop=='pipeline'">
                                 Cartera proyectos
                            </ion-col>
                            <ion-col size="44" v-if="tipo_prop=='mis_proyectos'">
                                 Proyectos de los clientes de {{$current_user_name}}
                            </ion-col>
                            <ion-col>
                                <Button icon="pi pi-file-excel"  style="float:right !important;"
                                @click="exportCSV($event)"
                                class="p-button-rounded p-button-success" 
                                />
                            </ion-col>
                        </div>
                </template>
                <template #loading>
                    Cargando datos de stock. Por favor, espera. {{device.tipo}}
                </template>

                    <Column v-for="(c, index) of columnas_proyectos" :field="c" 
                        :header="nombres_proyectos[c]" :key="c + '_' + index+ '_'+[device.tipo]" 
                        :sortable="paginador"
                        :headerStyle="anchos[c]" 
                        :bodyStyle="anchos[c]" 
                        
                        >
                        <template #body="slotProps" >
                          <div class="izquierda" v-if="txtCols(c)">
                              {{slotProps.data[c]}} 
                          </div>
                          <div class="izquierda" v-else-if="listCols(c)">
                               <span :innerHTML="listParser(slotProps.data[c])"></span>
                          </div>
                          <!-- <div class="izquierda" v-else-if="c =='l'">
                               {{plineParser(slotProps.data[c])}}
                          </div>-->
                          <div class="izquierda" v-else-if="c=='cn'">
                                 {{ GgCcParser(slotProps.data[c])}}
                          </div>
                           <div class="izquierda" v-else-if="c=='t'">
                                 {{ dateParsers(slotProps.data[c])}}
                          </div>
                          <div v-else class="derecha">
                              {{$filters.Entero(slotProps.data[c])}}
                          </div>
                        </template>
                    </Column>
                </DataTable>
           <!-- </ion-row>-->
        </ion-content>
        
        <ion-footer v-if="$rol==10" >
            <ion-toolbar :color="color_pag">
                <ion-segment :value="tipo_prop" mode="md" color="default"   @ionChange="cambiarEscenario($event)" >
                    <ion-segment-button value="pipeline" :disabled="tipo_prop==='pipeline'" >
                        <ion-label>Cartera</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="mis_proyectos" :disabled="tipo_prop==='mis_proyectos'">
                        <ion-label>Mis proyectos</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </ion-toolbar>
        </ion-footer>

    </ion-page>
</template>

<script>
    import { IonPage, IonContent,   IonRow,IonCol, IonBadge, IonIcon,
            IonSegment, IonSegmentButton, IonSpinner, IonToolbar, IonLabel,
            IonFooter ,
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import eventBus from "@/event-bus";
    import { Plugins } from '@capacitor/core';
    const { Storage } = Plugins;
    import { closeCircle, storefront } from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    import { export2Xls } from '@/utilities/services/xls_export';
    import { 
        etiquetas, colores_badges , colores_titulos ,titulos,
        filtros_activos, numberOfFilters , priorizacion_filtros
        } from '../../utilities/global_constants';

    export default defineComponent({
            name : "StockList",
            components: { 
                IonPage, IonContent, IonRow,IonCol, CabeceraComponent, IonBadge, IonIcon, 
                IonSegment, IonSegmentButton, IonSpinner, IonToolbar, IonLabel,
                IonFooter 
            } , 
            computed :{
                titulo_pag(){
                    //return this.tipo_prop == 'pipeline' ? 'Cartera de proyectos' : 'Proyectos de mis clientes'
                    return titulos[this.tipo_prop]
                },
                fontSize () { 
                    window.fake_console('PipelineList',`--texto-size : ${12+(this.device.tamanyo)}px`)
                    return `--texto-size : ${12+(this.device.tamanyo)}px;`
                }, 
                numFiltros () {
                    return (numberOfFilters[this.device.tipo] )+  1 
                },

                color_pag() {
                         
                          return  colores_titulos[this.tipo_prop]
                }, 
                anchos() {
                    const base = {
                        "sm" : {"px" : 10,"ob" : 28,"nc" : 28,"mp" : 18,"v" : 16},
                        "md" : {"px" : 10,"ob" : 35,"nc" : 30,"mp" : 25,"v" : 10},
                        "lg" : {"px" : 6,"ob" : 20,"bk" : 20,"nc" : 18,"ne" : 12,"nmp" : 18,"v" : 8},
                        "xl" : {"np" : 11,"ob" : 16,"bk" : 12,"nc" : 11,"ne" : 10,"l" : 10,"nmp" : 10,"nm" : 8,"t":6, "v" : 6}
                    }
                    /** Nos aseguramos que las definiciones de columnas sumen 100, sino aplicamos proporcionalidad  */
                    const factor = _.reduce(_.values(base[this.device.tipo]), (m,v)=> {return m+=(v/100)},0)
                    window.fake_console(factor)
                    const resultado = _.reduce(base[this.device.tipo], (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    window.fake_console(this.device.tipo, this.device.ancho,  factor, resultado)
                    return resultado
                }, /**
                     * Configuración de columnas 
                     */
                    columnas_proyectos() { 
                        return {
                            'sm' : ['px','ob', 'nc','mp', 'v'], 
                            'md' : ['px','ob', 'nc','mp', 'v'],  
                            'lg' : ['px','ob', 'bk','nc','ne','nmp','v'],  
                            'xl' : ['np','ob', 'bk','nc','ne','l','nmp','nm', 't', 'v']
                    }[this.device.tipo]
                    }, 
                /**
                     * Formatos específicos que haya que aplicar a alguna variable, por defecto todas se presentan como numero salvo que esten aqui. 
               
                
                parsers() {
                    return _.reduce(this.columnas_proyectos , (m,c) => {
                        const f = (this.txtCols(c)||this.listCols(c)|| [ 'cn'].indexOf(c)!=-1) ? (x)=> {return x} 
                                : c === 't' ? this.dateParsers
                                : this.$filters.Entero;
                        m[c] = f;
                        return m
                    }, {})
                } */
            },
            data() {    
                return {
                    
                    nombres_proyectos:{  
                        "v":"Venta Estimada", "m":"Marcas Posibles", "mp":"Marca Principal","ob" : "Obra / Proyecto" ,
                        "bk" : "Partida", "l" : "Product Line", "lx" : "Product Line", 
                        "nc" : "Nombre cliente", "c" : "Codcli", 
                        "p" : "Pdv", "px" :"Pdv", "n" : "Nif","np": "Nombre Pdv",  "e": "Exterior", 
                        "ne" : "Comercial Exterior", "nmp" : "Marca «Ganadora»", 
                        "t": "Fecha Revis.",
                        "nm" : "Otras Marcas posibles", "cn" : "Gran Cuenta","i" : "Global"
                        }, 
                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle, storefront, 
                    /** Constantes cargadas de constantes globales  */
                    etiquetas,colores_badges, 
                    filtros_activos, numberOfFilters , priorizacion_filtros, titulos,
                    /* 
                        Variables para cargar los valores de propiedades si vienen por route o 
                        el valor por defecto si estamos en una ruta genral 
                    */
                   tipo_prop :undefined, 
                   valor: undefined, 
                   /** Variable de control para actuar en mount/update */
                   ruta: undefined , 
                   rutabase : undefined, 
                   fetchUrl : undefined, 

                   /** 
                    * Variables para controlar la entrada de formatos 'top'
                    * En estos casos, no enseñamos el paginador,
                    * No se pueden ordenar los campos de la tabla 
                    * Los registros totales vienen definidos 
                    * Y el campo de ordenacion tb. 
                    */
                    filas: 10 , 
                    ordinal : null , 
                    paginador : true, 

                    /** Variable que controla el spinner de carga  */
                    loading: true, 

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde cargamos los datos de las peticiones xhttp tal como vienen 
                     */
                    datos: undefined ,
                    datos_full: undefined,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    
                    /**  
                     *   Filtros posibles del usuario, obtenidos en la carga inicial, 
                     *   solo los que pueden tener sentido para el y con valores 
                     *   ya filtrados (sus pdvs, sus marcas.. ) 
                    */
                    filtros:undefined, 
                    

                    /**
                     * Variable que tiene todos los filtros posibles que aplicamos en la vista  
                     */
                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                    /**
                     * Resto de filtros (obtenidos del server y que no sean el de punto de vista)
                     * El proceso sería : filtros --> [pov , ....rest] = [pov, filters]
                     */
                    filters :{},

                    /** Valores seleccionados para cada filtro, los posibles son los que estan en filters */
                    SelectedFilters: undefined, 

                    /** Filtro introducido desde la url para hacer una selección 
                     * en el caso en que el formato es 'single'
                     * Es decir, siempre pedimos todos los datos del pdv y filtramos en la tabla 
                     */
                    queryFilter : undefined, 
                    plines_labels :undefined, 
                    parsers: {
                            'px' : (x)=> x,  
                            'np' : (x)=> x,  
                            'mp' : (x)=> x,  
                            'ob' : (x)=> x,  
                            'bk' : (x)=> x,  
                            //'nc' : (x)=> x,  
                            'p' : (x)=> x,  
                            'l' : (x)=> x,  
                            'lx' : (x)=> x,  
                            'n' : (x)=> x,  
                            'e' : (x)=> x,  
                            'ne' : (x)=> x,  
                            'nmp' : (x)=> x, 
                            'ma': this.listParser, 
                            'nm': (x)=> {
                                return x.join(';   ')}, 
                            'cn' : this.GgCcParser, 
                            't': this.dateParsers,  
                            'nc': (x)=> x, 
                            'v' : this.$filters.Entero
                    }, 

                    
                }
            },  
            async updated(){
                
                window.fake_console('PipelineList','en el update 245')
                const route = useRoute();
                //window.fake_console('PipelineList',JSON.stringify(route))
                window.fake_console('PipelineList',this.ruta, route.path, route.query, this.rutabase)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('PipelineList','llamo al update ')
                    this.ruta = route.fullPath
                    this.ordenar(route)
                    this.procesoInicial()
                }         
                else {
                    window.fake_console('PipelineList','NO llamo al update')
                }       
            },
            async mounted() {
                this.loading = true;
                window.fake_console('PipelineList','en el mount 273')
                const route = useRoute();
                window.fake_console('PipelineList',this.ruta, route.path, route.query)
                this.ruta = route.fullPath
                this.rutabase = route.path
                window.fake_console('PipelineList',route.path, route.query)
                this.ordenar(route)
                this.procesoInicial()
                const lbs = await Storage.get({ key: 'stocks:labels:l' });
                this.plines_labels= JSON.parse(lbs.value)


            }, 
            methods :{
               /*Asignación del ancho de columnas  */
               AnchoCol(x) { return `width: ${(this.device.ancho-32)*x/100}px !important`}, 
               /** Lo cambiaremos por un simple dialog, para ver 4 datos no hace falta más  */
               ShowDetail(e) {
                window.fake_console(e.data)
                this.$router.push({
                    name: 'editar_proyecto', 
                    query: { project_data: JSON.stringify(e.data)
                }})
               }, 
                WillExpire(data) {
                    window.fake_console(data.t)
                    return data.t < (Date.now()+(30*86400000))  ? 'resaltar_proyecto' : null
                },
                txtCols(c) {
                    return ['px','np','mp','ob','bk','nc','p', 'l','lx','n', 'e','ne','nmp'].indexOf(c)!=-1 
                },
                listCols(c){
                    return ['ma','nm'].indexOf(c)!=-1 
                },
                listParser(x) {
                    const k = x.map(y=>`<li>${y}</li>`); 
                    window.fake_console( `<ol>${k}</ol>` )
                    return `<ol>${k}</ol>` 
                },
                dateParsers(x) {
                    return new Date(x).toISOString().slice(0,10).replace(/-/g,'.')
                },
                GgCcParser(x) {return x===1 ?  'G.C.': 'Resto'},

                async ordenar(route) {
                    this.ordinal = route.query.sort 
                    this.paginador = route.query.limit != undefined ? false : true
                    if (route.query.limit != undefined) {this.filas = parseInt(route.query.limit)}
                    window.fake_console('PipelineList',Date.now() +'acabo el proceso ordenar!')
                }, 

                async procesoInicial() {
                    const route = useRoute();
                    this.loading = true;
                    this.tipo_prop = route.params.tipo_prop
                    if (route.params.clase_prop!=undefined) {
                        this.clase= route.params.clase_prop
                        this.valor= route.params.valor_prop
                    }
                    const fl =  await Storage.get({ key: 'proyectos:filtros' });
                    window.fake_console('PipelineList',fl)
                    this.filtros = JSON.parse(fl.value) 
                    window.fake_console(this.filtros)
                    window.fake_console('PipelineList',Date.now() +'filtros recuperados')
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    /** este es el bloque de codigo que debe ejecutarse tanto si inicializamos como si actualizamos el componente  */
                    await this.mountFilters()
                    window.fake_console('PipelineList',Date.now() +'filtros montados ')
                    this.fetchData()
                    window.fake_console('PipelineList',Date.now() +'llamada a datos realizada ')
                }, 
                
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.filtrar()}, 250)
                }, 
                /** monta los filtros que toquen en función de la vista */
                async mountFilters(){
                    window.fake_console('PipelineList',this.filtros)
                    const aplicables = filtros_activos
                    window.fake_console('PipelineList',aplicables)
                    this.filters = _.pick(this.filtros, aplicables)
                    this.SelectedFilters = _.keys(this.filters).reduce((m,v)=>{m[v]=[];return m} ,{})

                }, 
                async fetchData(){ 
                    //const self = this
                    this.loading = true
                    /** 
                     *  Fijamos la url:
                     *  Si es un usuario 'multi', pedimos lo que viene en la url 
                     *  Si es un usario 'single', pedimos su pdv y añadimos el valor com queryFilter  */
                    const url =`/${this.tipo_prop}`
                    /** 
                     *  Verificamos si la url de descarga ha cambiado,  
                     *  Si es la misma no hacemos nada. 
                     * */
                    window.fake_console('PipelineList',url, this.fetchUrl)
                    if (url !=this.fetchUrl)
                    {
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('PipelineList','Es cache?? : ',result.request.fromCache)
                        const data = result.data
                        //this.datos = _.chain(data.data).map((b)=> {b['lx']=self.plines_labels[b['l']]; return b}).sortBy( (d)=> {return -d.v}).value()
                        this.datos = _.chain(data.data).sortBy( (d)=> {return -d.v}).value()
                        window.fake_console('PipelineList ',this.datos)
                    }
                    /* Utilizamos el queryFilter si existe */
                    if (this.clase !=undefined && this.valor !=undefined) {
                        window.fake_console('PipelineList',this.queryFilter)
                        const campo = this.clase
                        const valor = this.valor
                        const filtroQuery = this.filters[campo].filter((v)=> {return v['v'].indexOf(valor)!=-1})
                        this.SelectedFilters[campo] = filtroQuery
                        window.fake_console('PipelineList',this.SelectedFilters)
                        this.filtrar()
                    }
                    else  {
                        window.fake_console('PipelineList',' en ppio no filtro ')
                        window.fake_console('PipelineList',this.datos)
                        this.filtrar()
                    }
                    this.loading = false
                }, 

                eliminar(s,i){
                    this.SelectedFilters[s].splice(i,1)
                    this.filtrar()
                },
                async filtrar() {
                    window.fake_console(this.datos.slice(0,5))
                    window.fake_console('PipelineList',JSON.stringify(this.SelectedFilters))
                    const fl = Object.values(this.SelectedFilters).flat().reduce((m,v)=> {m[v['x']] = _.union(m[v['x']] ,v['v']); return m} ,{})
                    let filtro = _.pairs(fl)
                    window.fake_console('PipelineList',_.unzip(filtro))
                    let filtrados = this.datos
                    if (filtro.length!=0)
                        {_.each(filtro, function(f) {
                            window.fake_console(f)
                            window.fake_console(_.pluck(filtrados,'m'))
                            filtrados = f[0] == 'm' 
                               ?  _.filter(filtrados, (v)=> {return _.intersection(f[1], v[f[0]]).length>0 })
                               :  _.filter(filtrados, (v)=> {return f[1].indexOf(v[f[0]])!=-1 })
                        })}
                    window.fake_console(filtrados)
                    if (this.termino!=undefined && this.termino.length>3){
                        filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(this.termino)!=-1})
                    }
                    const ordenDefecto = this.ordinal!=undefined ? this.ordinal : 'dm'
                    const limite = this.limite!=undefined ? this.filas : filtrados.length
                    filtrados = this.tipo_prop =='ajustes' 
                        ? _.sortBy(filtrados, (e)=>e[ordenDefecto]).slice(0,limite)
                        : _.sortBy(filtrados, (e)=>-e[ordenDefecto]).slice(0,limite)

                    window.fake_console(filtrados)
                    this.filtrados = filtrados
                    window.fake_console('PipelineList',this.filtrados.length, this.filas, this.paginador)
                    if (this.filtrados.length <=this.filas) { this.paginador = false}
                        else { this.paginador = true}
                    window.fake_console('PipelineList',this.filtrados.length, this.filas, this.paginador)
                    this.loading = false
                    window.fake_console('PipelineList',filtrados.length)
                    window.fake_console('PipelineList',JSON.stringify(filtrados).slice(0,100))
                },
                exportCSV() {
                 const nombre = this.titulo_pag.replace(/ /g,'.').replace(/[()]/g,'').toLowerCase()
                 window.fake_console(JSON.stringify(this.parsers))
                 export2Xls(this.$refs.datatable,this.parsers, nombre)
                },
                async cambiarEscenario(ev){
                    const escenario = ev.detail.value
                    window.fake_console('PipelineList',escenario)
                    this.$router.replace(`/listado/${escenario}`)
                },
            }
            
})
</script>

<style scoped>
    ion-segment-button > ion-label {
        color : var(--ion-color-light) !important;
        margin-top: 6px; 
        margin-bottom : 6px; 
    }
    ion-segment-button {
        min-height: 24px !important;
    }
    ion-segment {
        min-height: 30px !important;
    }
    ion-row.spin{
        min-height: 300px;
    }
    spin {
        height: 75%
    }
    ion-spinner {
        width:75px; 
        height:75px;
        display: block;
        margin: auto;
           
    }

    ion-badge {
        margin-top: 6px; 
        margin-right:6px; 
        padding-top: 0px;
        padding-right: 12px; 
        padding-left: 12px; 
        padding-bottom: 4px; 
    }
    ion-icon.badge {
        padding-top: 4px; 
        font-size:1.25rem; 
    }
    
    ion-row.selectores {
        border: 1px solid var(--ion-color-light);
    }
    .derecha {
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        text-align: left !important;
        
    }
    tr.resaltar_proyecto {
        background-color: var(--color-resaltado-medio) !important;  
    }

</style>

