<template>

  <ion-row   size="large"  class=" resultado ion-text-center ion-margin-vertical ion-justify-content-center">
    <ion-col class="codigo ion-align-self-baseline ion-align-self-center" size="4">  {{pin[0] === undefined ? '' : pin[0]}}</ion-col >
    <ion-col class="codigo ion-align-self-baseline ion-align-self-center" size="4">  {{pin[1] === undefined ? '' : pin[1]}}</ion-col >
    <ion-col class="codigo ion-align-self-baseline ion-align-self-center" size="4">  {{pin[2] === undefined ? '' : pin[2]}}</ion-col >
    <ion-col class="codigo ion-align-self-baseline ion-align-self-center" size="4">  {{pin[3] === undefined ? '' : pin[3]}}</ion-col >
  </ion-row>
  
  <ion-grid class="ion-text-center ion-justify-content-center">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('1')">1</ion-button>
        </ion-col>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('2')">2</ion-button>
        </ion-col>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('3')">3</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('4')">4</ion-button>
        </ion-col>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('5')">5</ion-button>
        </ion-col>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('6')">6</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('7')">7</ion-button>
        </ion-col>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('8')">8</ion-button>
        </ion-col>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('9')">9</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
          <ion-col>
          <ion-button fill="clear" size="large"  class="ion-no-padding" @click="handleInput('clear')">Clear</ion-button>
        </ion-col>
        <ion-col>
          <ion-button shape="round"  size="large"  fill="outline"  @click="handleInput('0')">0</ion-button>
        </ion-col>
        <ion-col>
          <ion-button fill="clear" size="large"  @click="submitForm()">OK</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>



</template>

<script>
    import {    IonGrid, IonRow, IonCol,  IonButton, alertController } from '@ionic/vue';
    import { defineComponent } from "vue";

    export default defineComponent({
        name : "PinPadComponent",
        components: {   IonGrid, IonRow, IonCol,  IonButton},
        props :['input_code'],
        data() {
                return {
                    pin:['*','*','*','*'], 
                    contador: 0
                } 
                
            },
        methods : {
              handleInput(pin) {
                window.fake_console(this.input_code)
                window.fake_console(pin, this.pin.length)
                if (pin === "clear") {
                  window.fake_console('1')
                  this.pin = ['*','*','*','*'];
                  this.contador = 0 ;
                  return;
                }
                if (this.pin.length > 4) {
                  window.fake_console('2')
                  return;
                  }
                this.pin[this.contador] = pin
                this.contador+=1
                window.fake_console(this.pin)
              },
            clearForm(){
                this.pin = ['*','*','*','*']
                this.contador = 0 
            },
            async submitForm(){
              const post = await this.$http.post('/suscribe',{'telegram': {'t' : this.input_code, 'v' : this.pin.join('') } })
                    if (post.data.status ===false ) {
                        alert('Algo no hay ido bien, contacta con ATI...')
                    }
                    else {
                     this.confirmacion()
                    }
                    
            }, 
            async confirmacion () {
                 var self = this;
                 const alerta = await alertController
                        .create({
                            header: 'Confirmacion', 
                            message: 'Te has adherido, recibiras en tu Telgram un primer mensaje de bienvenida. No olvides activar las notificaciones para facilitarte el uso ',
                            mode : "md",
                            buttons: [
                                {
                                text: 'Si',
                                handler: async () => { self.$router.replace('/resumen/pipeline')},
                                },
                            ]
                        })
                    return alerta.present(); 

                }
    
        }
    })
</script>

<style scoped>
  .codigo {
    border-bottom: 1px solid var(--ion-color-medium);
    margin: 25px;
    font-size: 3.5rem; 
    text-align: center;
    color: var(--ion-color-medium);
  }
  ion-button {
    font-size: 1.8rem; 
    width: 75%;
  }
  .resultado {
    width: 100%;
    height: 20%;
    /*margin-top: 24px; 
    padding-bottom: 24px; */
  }
</style>