<template>
    <ion-page>
        <ion-content v-if="dat">
            <ion-card>
                <ion-card-header  class="ion-padding ion-margin" color="light" >
                    <ion-card-subtitle >{{dat.const}}</ion-card-subtitle>
                    <ion-card-title color="primary"><small>{{dat.nom}}</small></ion-card-title>
                </ion-card-header>
                <ion-card-content class="ion-padding ion-margin">
                {{dat.desc}}
                </ion-card-content>
                <!--footer-->
                <ion-card-header class="ion-no-margin ion-no-padding">

                    <!-- NOTA INTERNA DE MARKETING-->
                    <ion-card-subtitle   class="ion-padding-start ion-margin-start" v-if="dat.nota" >
                        Nota interna
                    </ion-card-subtitle>
                    <ion-row v-if="dat.nota">
                        <ion-col class="ion-padding-start ion-margin-start">
                            {{dat.nota}}
                        </ion-col>
                    </ion-row>

                    <ion-card-subtitle   class="ion-padding-start ion-margin-start ion-margin-top negrita">
                        Datos de contacto
                    </ion-card-subtitle>
                    <ion-row >
                        <ion-col size="28" class="ion-padding-start ion-margin-start negrita">
                            Mails de contacto
                        </ion-col>
                        <ion-col size="16" >
                            Telefonos
                        </ion-col>
                    </ion-row>
                    <ion-row class="peque">
                        <ion-col size="24" v-html="listParser(dat.mails,'mails')" class="peque">
                        </ion-col>
                        <ion-col size="24" v-html="listParser(dat.tels,'tels')" class="peque">
                        </ion-col>
                    </ion-row>

                    <ion-button class="derecha" color="danger" @click="cerrar">Cerrar</ion-button>
                </ion-card-header>

           
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script>
    import {    
            IonPage, IonContent,IonRow,IonCardTitle,IonCol,
            IonCard, IonCardContent, IonCardHeader,IonCardSubtitle, IonButton
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import eventBus from "@/event-bus";
    export default defineComponent({
        name : "LeadsDetail",
        components: {  
            IonPage,  IonContent,IonRow, IonCardTitle,IonCol,
            IonCard, IonCardContent, IonCardHeader,IonCardSubtitle, IonButton
        },
        props : {
            dat: Object
        }, 
        methods: {
            cerrar() {
                eventBus().emitter.emit('cerrar-modal-detalle')
            },
            listParser(x, col) {
                    const separador = col=='mails'? ',' : '/'
                    if (x.length<5) { return '-'}
                    else {
                        const k = x.split(separador).map(y=>`<li><ion-label>${y}</ion-label></li>`).join(''); 
                        return `<ul>${k}</ul>` 
                   }                   
                },
        }
})
</script>

<style scoped>
    .negrita { font-weight: bold;}
    ion-card-header {
        border-bottom: 1px var(--ion-color-light) solid;
    }
    ul {
        padding-left:16px !important;
    }
    .padded {
        padding-left:40px !important;
        font-weight: bold;
    }
    .peque {
        font-size: 0.8rem;
    }
    ion-card-title {
        font-style: normal;
    }
    ion-card {
        min-height: 90%;
        min-width: 90%;
    }
    ion-button.derecha {
        float: right ;
        margin-top: 16px;
        margin-right: 16px ;  
        width: 8rem; 
        height: 2.2rem;
        --border-radius: 6px;
    }
</style>