<template>
  <ion-page>      
      <cabecera-component 
            titulo="2. Crea o elige un proyecto para el cliente  " 
            color="primary"
        />
        <ion-content class="ion-padding" v-if="$editor">
                <div class="crear">
                <ion-toolbar>
                        <ion-title class="completo">Crea un proyecto</ion-title>
                </ion-toolbar> 
                    
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-field p-col-12">
                                    <div class="p-col">
                                        <InputText v-model="ob_name" id="obname"
                                            type="text" placeholder="Nombre nuevo proyecto, minimo 10 letras" 
                                            :class="(ob_name!=undefined && ob_name.length<10) ? 'p-invalid' : ''"
                                            />
                                        <label class="etiqueta-error" for="obname" v-if="(ob_name!=undefined && ob_name.length<10)" >Como minimo 10 caracteres</label>
                                    </div>
                                </div>
                            </div>
                            <ion-row>
                                <ion-col size="48">
                                    <ion-button class="derecha" :disabled="(ob_name==undefined || ob_name.length<10)" @click="createProject"> Crear </ion-button>
                                </ion-col>
                            </ion-row>
                            
                        </div>

                </div>
                <div class="elegir" v-if="datos.length>0">
                <ion-toolbar>
                        <ion-title class="completo">O elige uno existente</ion-title>
                </ion-toolbar> 

                    <ion-toolbar>
                        <ion-searchbar  :value="search" debounce="100" 
                            @ionChange="lanzarBusqueda($event)"
                            @ionClear="lanzarBusqueda($event)"
                            @ionCancel="lanzarBusqueda($event)"
                            autocomplete="off" autocorrect="off" 
                            autocapitalize="off" spellcheck="false"
                            :placeholder="'Filtra y elige (3 letras minimo)'" 
                            mode = "md">
                        </ion-searchbar>
                    </ion-toolbar>

                    <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                        <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                            <ion-spinner color="tertiary" v-if="loading"></ion-spinner>
                        </ion-col>
                    </ion-row>
                
                    <ion-grid>


                        <ion-list lines="full" mode="md" v-if="!loading">
                            <ion-item v-for="(proyecto,index) in filtrados" v-bind:key="index"  @click="setSelectedProject(proyecto)">
                                <ion-grid class="sin-borde"  :class="{ selected : seleccionado !=undefined && seleccionado._id === proyecto._id}" >
                                    <ion-row class="ion-align-items-start" >
                                        <ion-row class="elemento">
                                        <h4 class="nombre_cortado"><small>{{proyecto['ob']}}</small></h4>
                                        </ion-row>
                                        <ion-row class="ultimo">
                                            <p><small>Cuenta: {{proyecto['nc']}} </small></p>    
                                        </ion-row>  
                                    </ion-row>
                                </ion-grid>  
                            </ion-item>
                        </ion-list>
                        <hr/>
                        <ion-row>

                            <ion-col size="48">
                                <ion-button class="derecha" color="primary" :disabled="seleccionado==undefined" @click="settedProject"> Siguiente</ion-button>
                            </ion-col>
                        </ion-row>
                    
                    </ion-grid>
                </div>
        </ion-content>

        <!-- Si no tiene cartera de clietnes,  no puede ver nada -->
        <ion-content  class="ion-padding" v-if="!$editor">
            <h2> 
                Parece que no tienes cartera de clientes asignada....
                No puedes ver esta pagina 
            </h2>
        </ion-content>
        <ion-footer color="primary">
                <ion-row style="margin-top: 16px; margin-bottom:16px;">
                    <ion-col size="16">
                            <ion-button class="derecha" color="danger" @click="cancelar">Cancelar</ion-button>
                    </ion-col>
                    <ion-col size="16">
                            <ion-button class="derecha" color="warning" @click="goBack">Atras</ion-button>
                    </ion-col>
                </ion-row>
        </ion-footer>
    </ion-page>
</template>



<script>
    import { IonContent, IonSearchbar, IonCol,IonPage,IonButton,
             IonGrid,  IonRow, IonSpinner,
            IonToolbar,IonTitle,IonFooter,
            IonList, IonItem
        } from '@ionic/vue';
    
    import { defineComponent } from 'vue';
    import {pluck, max} from 'underscore';
    import CabeceraComponent from '../CabeceraComponent.vue';
    export default defineComponent({
        name: 'SelectProject',
        components: { 
            IonContent, IonSearchbar,IonCol,IonPage,IonButton,
            IonGrid,  IonRow,IonToolbar,
            IonSpinner,IonTitle,IonFooter,
            IonList, IonItem, CabeceraComponent
            }, 
        props :['customer_data'],
        data() {
            return {
                loading :true, 
                search: [], 
                datos: [], 
                filtrados:[], 
                seleccionado : undefined , 
                ob_name: undefined, 
                proyecto: undefined , 
                cust_data: undefined 
            }
        }, 
        mounted() {
            /** Solo puedes verla si eres supervisor, sino te redirige a  */
            if (this.$editor === true && this.customer_data != undefined) {
                this.cust_data = JSON.parse(this.customer_data)
                this.fetchData()
            }
            else  {
                alert('No puedes ver esta pagina, te redirigimos a tu home ')
                this.$router.replace('/resumen/pipeline')
            }
        },
        methods : {
            async fetchData(){ 
                this.loading = true;
                window.fake_console('en la solicitud de datos')
                const result = await this.$http.get('/proyectos_cliente') 
                const dt = result.data
                const cust = this.cust_data.c
                window.fake_console(result.data)
                window.fake_console(this.cust_data, cust)
                this.datos = (cust == undefined||dt[cust]==undefined ) ? []:  dt[cust] 
                window.fake_console(this.datos)
                this.filtrados = this.datos
                this.loading = false; 
            },
            async filterData(termino){
                    this.filtrados = 
                        (termino !== '' && termino !== undefined &&  termino.length>0 )
                            ? this.datos.filter( (e)=> {return JSON.stringify(e).toLowerCase().indexOf(termino.toLowerCase()) !==-1 })
                            : this.datos
                    this.filtrados = this.filtrados.slice(0,5)
            }, 
            esSeleccionado(cli){
                return this.seleccionado.c == cli.c ? 'elemento ion-align-items-start seleccinado' :  'elemento ion-align-items-start no-seleccionado'
                
            },
            lanzarBusqueda(ev){
                    const termino =ev.target.value 
                    if (termino.length>=3) {this.filterData(termino)}   
                    else {this.filtrados = this.datos.slice(0,5)}
            },
            setSelectedProject(proyecto){
                const previo = this.seleccionado
                window.fake_console(proyecto)
                window.fake_console(previo, proyecto, previo != undefined && previo._id != proyecto._id)
                if (previo == undefined || previo._id != proyecto._id) {
                    this.seleccionado=proyecto 
                }
                else {this.seleccionado = undefined}
            } , 
            settedProject(){
                const projectId = `${this.seleccionado._id}:B${('0'+(this.seleccionado.last+1)).slice(-2)}`
                this.proyecto = {
                    'c': this.cust_data.c, 
                    'p': this.cust_data.p, 
                    'n': this.cust_data.nf,
                    'i': this.cust_data.i, 
                    'e': this.$current_user, 
                    'ob': this.seleccionado.ob , 
                    '_id' :projectId
                }
                window.fake_console(this.proyecto)
                //eventBus().emitter.emit('close-project-selection', this.proyecto)
                this.$router.push({
                    name: 'new_project_details', 
                    query: { proyecto_data: JSON.stringify( this.proyecto)
                }})

            },
            createProject(){
                window.fake_console('entro en la funcion de creacion')
                window.fake_console(this.datos)
                window.fake_console(max(pluck(this.datos,'pnumber'))+1)
                const ultimo  = this.datos.length==0 ? 1 : (max(pluck(this.datos,'pnumber'))+1)
                const projectId = ('0'+ultimo).slice(-2)
                window.fake_console(ultimo, projectId)
                this.proyecto =  {
                    'c': this.cust_data.c, 
                    'p': this.cust_data.p, 
                    'n': this.cust_data.nf,
                    'i': this.cust_data.i, 
                    'e': this.$current_user, 
                    'ob': this.ob_name , 
                    '_id' : `${this.$current_user}:${this.cust_data.c}:P${projectId}:B01`
                }
                window.fake_console(this.proyecto)
                this.$router.push({
                    name: 'new_project_details', 
                    query: { proyecto_data: JSON.stringify( this.proyecto)
                }})
            }, 
            cancelar(){
                this.$router.replace('/resumen/pipeline')
            },
            goBack(){
                this.$router.go(-1)
            }
        }
        });
</script>

<style scoped>
    .etiqueta-error {
        color:var(--ion-color-darkdanger);
        font-style:italic;
        padding-left: 1rem;
    }
    ion-list{
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 12px;
    }
    ion-row{ width: 100%; }
    ion-col{ padding: 2px !important;  }
    ion-item {
        --inner-padding-end: 0px !important; 
        --padding-start: 0px !important; 
    }
    ion-row.elemento {
        margin-top: 10px  !important;
    }
    ion-row.ultimo {
        margin-bottom: 10px  !important;
    }
    ion-grid.sin-borde{
        padding: 0px !important; 
        margin: 0px !important; 
        width: 100% !important;
    }
    h4 { margin:0px !important; }
    p {
        margin-top:0px !important;
        margin-bottom:0px !important;
    }
    h4,p {
        padding-left: 10px;
        padding-right: 4px;}
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    ion-button.derecha {
        float:right;
        width: 8rem; 
        height: 2.2rem;
        --border-radius: 6px;
    }
    .selected {
        background-color: var(--color-resaltado-negativo) !important;  
    }
    div.crear, div.elegir {
        padding: 8px;
        border: 1px solid var(--ion-color-light);
        margin-top:16px;
    }
    ion-title.completo {
        padding-left: 16px; 
        padding-right: 16px; 
    }
</style>
