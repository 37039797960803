<template>
  <ion-page>      
      <CabeceraComponent
            titulo="1. Selecciona el cliente " 
            color="primary"
        />
        <ion-content class="ion-padding" v-if="$editor">
            <ion-toolbar>
                    <ion-searchbar  :value="search" debounce="100" 
                        @ionChange="lanzarBusqueda($event)"
                        @ionClear="lanzarBusqueda($event)"
                        @ionCancel="lanzarBusqueda($event)"
                        autocomplete="off" autocorrect="off" 
                        autocapitalize="off" spellcheck="false"
                        :placeholder="'Filtra y elige (3 letras minimo)'" 
                        mode = "md">
                    </ion-searchbar>
                </ion-toolbar>
            <ion-grid>
                <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                    <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                        <ion-spinner color="tertiary" v-if="loading"></ion-spinner>
                    </ion-col>
                </ion-row>
                <ion-list lines="full" mode="md" v-if="!loading">
                    <ion-item v-for="(cliente,index) in filtrados" v-bind:key="index"  @click="setSelectedCustomer(cliente)">
                        <ion-grid class="sin-borde"  :class="{ selected : seleccionado !=undefined && seleccionado.c === cliente.c}" >
                            <ion-row class="ion-align-items-start" >
                                <ion-row class="elemento">
                                   <h4 class="nombre_cortado"><small>{{cliente['n']}}</small></h4>
                                </ion-row>
                                <ion-row>
                                    <p><small>Cuenta: {{cliente['c']}}</small></p>    
                                </ion-row>  
                                <ion-row class="ultimo">
                                    <p><small>Nif: {{ cliente['nf']}} </small></p>    
                                </ion-row>  
                            </ion-row>
                        </ion-grid>  
                    </ion-item>
                </ion-list>
                <hr/>
                <ion-button class="izquierda" color="danger" @click="cancelar">Cancelar</ion-button>
                <ion-button class="derecha" color="primary" :disabled="seleccionado==undefined" @click="settedCustomer"> Siguiente</ion-button>
            </ion-grid>
        </ion-content>

        <!-- Si no tiene cartera de clietnes,  no puede ver nada -->
        <ion-content  class="ion-padding" v-else>
            <h2> 
                Parece que no tienes cartera de clientes asignada....
                No puedes ver esta pagina 
            </h2>
        </ion-content>
    </ion-page>
</template>



<script>
    import { IonContent, IonSearchbar, IonCol,IonPage,IonButton,
             IonGrid,  IonRow, IonSpinner,
            IonToolbar,
            IonList, IonItem
        } from '@ionic/vue';
     import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
     import { Plugins } from '@capacitor/core';
    const { Storage } = Plugins;
    export default defineComponent({
        name: 'NewProject',
        components: { 
            IonContent, IonSearchbar,IonCol,IonPage,IonButton,
            IonGrid,  IonRow,IonToolbar,
             IonSpinner,
            IonList, IonItem, CabeceraComponent
            }, 
        data() {
            return {
                loading :true, 
                search: [], 
                datos: [], 
                filtrados:[], 
                seleccionado : undefined 
            }
        }, 
        mounted() {
            /** Solo puedes verla si eres supervisor, sino te redirige a  */
            if (this.$editor === true) {
                this.fetchData()
            }
            else  {
                alert('No puedes ver esta pagina, te redirigimos a tu home ')
                this.$router.replace('/resumen/pipeline')
            }
        },
        methods : {
            cancelar() {
                this.$router.go(-1)
            },
            async fetchData(){ 
                this.loading = true;
                const mpl = await this.$http.get('/marcas_pline')
                window.fake_console(mpl.data)
                await Storage.set({ key: `proyectos:marcas_pline`, value: JSON.stringify(mpl.data)});
                const result = await this.$http.get('/clientes') 
                this.datos = result.data.filter(x=>x.i!=98001)
                window.fake_console(result.data)
                window.fake_console(this.datos)
                this.filtrados = this.datos.slice(0,5)
                this.loading = false; 
            },
            async filterData(termino){
                    this.filtrados = 
                        (termino !== '' && termino !== undefined &&  termino.length>0 )
                            ? this.datos.filter( (e)=> {return JSON.stringify(e).toLowerCase().indexOf(termino.toLowerCase()) !==-1 })
                            : this.datos
                    this.filtrados = this.filtrados.slice(0,5)
            }, 
            esSeleccionado(cli){
                return this.seleccionado.c == cli.c ? 'elemento ion-align-items-start seleccinado' :  'elemento ion-align-items-start no-seleccionado'
                
            },
            lanzarBusqueda(ev){
                    const termino =ev.target.value 
                    if (termino.length>=3) {this.filterData(termino)}   
                    else {this.filtrados = this.datos.slice(0,5)}
            },
            setSelectedCustomer(cliente){
                const previo = this.seleccionado
                window.fake_console(cliente)
                if (previo == undefined || previo.c != cliente.c) {
                    this.seleccionado=cliente 
                }
                else {this.seleccionado = undefined}
            } , 
            settedCustomer(){
                this.$router.push({
                    name: 'new_project_project', 
                    query: { customer_data:  JSON.stringify(this.seleccionado)
                }})
            }
        }
        });
</script>

<style scoped>
    ion-list{
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 12px;
    }
    ion-row{ width: 100%; }
    ion-col{ padding: 2px !important;  }
    ion-item {
        --inner-padding-end: 0px !important; 
        --padding-start: 0px !important; 
    }
    ion-row.elemento {
        margin-top: 10px  !important;
    }
    ion-row.ultimo {
        margin-bottom: 10px  !important;
    }
    ion-grid.sin-borde{
        padding: 0px !important; 
        margin: 0px !important; 
        width: 100% !important;
    }
    h4 { margin:0px !important; }
    p {
        margin-top:0px !important;
        margin-bottom:0px !important;
    }
    h4,p {
        padding-left: 10px;
        padding-right: 4px;}
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    ion-button.derecha {
        float:right;
        width: 8rem; 
        height: 2.2rem;
        --border-radius: 6px;
    }
    ion-button.izquierda {
        float:left;
        width: 8rem; 
        height: 2.2rem;
        --border-radius: 6px;
    }
    .selected {
        background-color: var(--color-resaltado-negativo) !important;  
    }

</style>
