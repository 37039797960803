<template>
    <ion-page>
        <CabeceraComponent 
            titulo="Valida tu suscripcion" 
        />
        <ion-content class="ion-padding">
              <PinPadComponent :input_code="telegram_id"/>
        </ion-content>
          
    </ion-page>
</template>

<script>
    import {   IonPage, IonContent, } from '@ionic/vue';
    import PinPadComponent from '@/components/PinPadComponent';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import { useRoute } from 'vue-router';
    export default defineComponent({
        name : "Telegram",
        components: {  IonPage, IonContent, PinPadComponent, CabeceraComponent},
        
        data() {
            return {
                telegram_id : undefined, 
                codigo : undefined
            }
        },
        async mounted() {
                const route = useRoute();
                this.telegram_id = route.params.telegram_id 
        }, 
        methods: {
           doNothing() {window.fake_console(1)}                
            }       
    })

</script>

<style scoped>
    ion-grid.google {
        height: 70%;
        margin-top: 20%;
        margin-bottom: 10%;
        width: 90%;
    }
    ion-row.google {
        height: 50%;
    }
</style>