<template>
    <ion-col>
        <ion-card :class="resa">
            <ion-card-header v-if="titulo.length>0 && titulo!=undefined">
                <ion-card-subtitle size="small"  
                    v-if="titulo.length>0 && titulo!=undefined">
                {{titulo}}          
            </ion-card-subtitle>

            </ion-card-header>

            <ion-card-content :class="izq" v-if="detalle.length>0 && detalle!=undefined">
                <ion-row>
                    <ion-col class=" separata ion-float-right ion-text-end" v-if="nota">
                        {{detalle}}
                    </ion-col>
                    <ion-col class=" media_separata ion-float-right ion-text-end" v-if="dedo">
                        {{detalle}}
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class=" separata ion-float-left ion-text-start" v-if="nota">
                        <ion-note class="ion-text-start"><small>{{nota}}</small></ion-note>
                    </ion-col>
                    <ion-col class=" con_icono ion-float-left ion-text-start" v-if="dedo">
                        <ion-icon 
                            :icon="dedo>0 ? thumbsUp : thumbsDown" 
                            :color="dedo>0 ? 'darksuccess' : 'darkdanger'"
                            v-if="dedo"></ion-icon>
                    </ion-col>
                </ion-row>    
                
            </ion-card-content>
        </ion-card>
    </ion-col>
</template>

<script>
    import {    IonCol,IonRow,
                IonCard, IonCardHeader, 
                IonCardSubtitle,IonIcon,
                IonCardContent, IonNote                
            } from '@ionic/vue';
    import { thumbsDown,thumbsUp } from 'ionicons/icons';

    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "TarjetaPipeline",
            components :  {    
                IonCol,IonCard, IonCardHeader, IonRow,
                IonCardSubtitle,IonIcon,
                IonCardContent, IonNote
            }, 
            data() {
                return {                    
                    thumbsDown,thumbsUp,
                    izq: this.izquierda==true ? 'izquierda' : 'derecha', 
                    resa : this.resaltar == true && this.dedo>=0 ? 'resaltar_positivo' 
                         : this.resaltar == true && this.dedo<0 ?  'resaltar_negativo'
                         :''
                }
            },
            props: ['titulo','detalle','nota','izquierda', 'resaltar','dedo']
    })
</script>

<style scoped>
        ion-icon {
            font-size: 22px
        }
        ion-note {
            width:100%;
            text-align: left;
        }
        ion-col { padding: 4px;}
        ion-card {
            margin:6px  2px !important;
        }
        ion-card.resaltar_positivo {
            border:solid 2px var(--ion-color-darksuccess) !important; 
            --ion-background-color: #f8fff8 !important;
            margin: 4px 2px !important;
        }
        ion-card.resaltar_negativo {
            border:solid 2px var(--ion-color-darkdanger) !important; 
            --ion-background-color: #fff8f8 !important; 
            margin: 4px 2px !important;
        }

        ion-card-content {
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 4px; 
            padding-right: 4px;
            text-align: right; 
        }
        ion-card-content.derecha {
            text-align: right; 
        }
        ion-card-content.izquierda {
            padding-left:8px;
            text-align: left; 
        }
        
        ion-card-subtitle{
            text-align: center;
        }
        ion-card-header {
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 4px ; 
            padding-bottom : 4px; 
        }
        ion-col.separata {
            padding-top: 2px  !important;
            padding-bottom: 2px  !important;
        }
        ion-col.con_icono {
            padding-top:0px;
            padding-bottom : 0px;
        }
        ion-col.media_separata {
            padding-top: 2px  !important;
            padding-bottom: 0px  !important;

        }
</style>