
import { createRouter, createWebHistory } from '@ionic/vue-router';
import eventBus from "@/event-bus";

import ResumenPipeline from '@/pages/pipeline/ResumenPipeline.vue'

import NewProject from '@/pages/pipeline/NewProject.vue'
import EditProject from '@/pages/pipeline/EditProject.vue'

import SelectProject from '@/components/pipeline/SelectProject.vue';
import DefineProjectBlock from '@/components/pipeline/DefineProjectBlock.vue';
import PipelineList from '@/pages/pipeline/PipelineList.vue'
import ComercialesZero from '@/pages/pipeline/ComercialesZero.vue'

import LeadsUploader from '@/pages/leads/LeadsUploader.vue'
import LeadsList from '@/pages/leads/LeadsList.vue'

import OfertaDetail from '@/pages/ofertas/OfertaDetail.vue'

import Login from '@/pages/Login.vue'
import Telegram from '@/pages/Telegram.vue'
import firebase from 'firebase/app';
import 'firebase/auth';

const routes = [
  {
    path: '/',
    component: () => import ('@/pages/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },


  {
    path: '/suscribe/:telegram_id',
    name: 'JoinTelegram',
    component: Telegram, 
    props: (route) => ({ ...route.query }),
    meta: {
      requiresAuth: true
    }
  },


  /** Resumen de proyectos general (scope completo del usuario) */
  {
    path: '/resumen/:tipo(pipeline)',
    name: 'resumen_pipeline',
    component: ResumenPipeline, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },
 
  /** Listado de proyectos general del usuario 
   *  Si hay que entar con filtro se pasan como route.query
   */
 
  {
    path: '/listado/:tipo_prop(pipeline)',
    name: 'pipeline_proyectos',
    component: PipelineList, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listado/:tipo_prop(mis_proyectos|pipeline)/:clase_prop(pdvs|marcas|plines|exts)/:valor_prop',
    name: 'PipelineWithFilters',
    component: PipelineList, 
    props: (route) => ({ ...route.query }),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/listado/:tipo_prop(mis_proyectos)',
    name: 'mis_proyectos',
    component: PipelineList, 
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/listado/:tipo_prop(to_expire)',
    name: 'caducan',
    component: PipelineList, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listado/:tipo_prop(new_projects)',
    name: 'nuevos_proyectos',
    component: PipelineList, 
    meta: {
      requiresAuth: true
    }
  },

  /** Crear un nuevo proyecto 
    * Solo puede de su cartera de clientes asignados, 
    * Deberá decir si es una partida de un proyecto existente o un nuevo proyecto 
    * Tiene 3 pasos: elegir cliente, elegir o definir proyecto, detallar la partida (marcas... )
   */
 
    {
      path: '/new/project/customer',
      name: 'new_project_customer',
      component: NewProject, 
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new/project/project',
      name: 'new_project_project',
      component: SelectProject, 
      props: (route) => ({ ...route.query }),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new/project/details',
      name: 'new_project_details',
      component: DefineProjectBlock, 
      props: (route) => ({ ...route.query }),
      meta: {
        requiresAuth: true
      }
    },
    

  /** Editar proyecto
    * Solo puede de su cartera de clientes asignados, 
    * Deberá decir si es una partida de un proyecto existente o un nuevo proyecto 
   */
 
    {
      path: '/editar/proyecto',
      name: 'editar_proyecto',
      component: EditProject, 
      props: (route) => ({ ...route.query }),
      meta: {
        requiresAuth: true
      }
    }, 

   
    {
      path: '/comerciales/zero',
      name: 'comerciales_zero',
      component: ComercialesZero, 
      meta: {
        requiresAuth: true
      }
    },

    /** 
   * Resumen de proyectos, pero SOLO los que son propiedad (puede crear o actualizar) la persona
   * Rutas para filtrar solo los proyectos como comercial de un gerente o jefe de ventas
   * Son solo visibles por gerentes y jefes de ventas, los perfiles mixtos que tienen proyectos propios y supervisan. 
   */
   {
      path: '/resumen/:tipo(mi_pipeline)',
      name: 'resumen_mi_pipeline',
      component: ResumenPipeline, 
      props: true, 
      meta: {
        requiresAuth: true
      }
    },




  {
    path: '/listado/oportunidades',
    name: 'leads_list',
    component: LeadsList, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/subir/oportunidades',
    name: 'upload_leads',
    component: LeadsUploader, 
    props: true, 
    meta: {
      requiresAuth: true
    }
  },



  {
    path: '/oferta/:ofe_id',
    name: 'detalle_oferta',
    component: OfertaDetail, 
    meta: {
      requiresAuth: true
    }
  },





  /*{
    path: '/proyectos_clientes',
    name: 'proyectos_clientes',
    component: ResumenPipeline, //ListaProyectosClientes, 
    meta: {
      requiresAuth: true
    }
  },*/

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



/** miramos en casda caso si estamos locados con algún usuario  */
router.beforeEach((to, from, next) => {
  window.fake_console(from,to,next)
  const currentUser = firebase.auth().currentUser;
  window.fake_console(currentUser, to)
  window.fake_console('index86 : en el router desde: '+from.path+' hacia : '+to.path) 
  
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    eventBus().emitter.emit('deeplink',to);

    window.fake_console('Redireccion a login, intento pagina y no hay credencial')
    next('/');
  } 
  else {
    window.fake_console(' cualquier otro caso ')
    next();
  }
});


export default router
