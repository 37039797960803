<template>
    <ion-page>
        <ion-content >
            <h1>AQUI VA EL CONTENIDO DE LAS OFERTAS</h1>

            <ul>
                <li>Llegamos aqui con una llamada a la ruta (interna) /oferta/{id_oferta: pdv.suc.num_oferta}</li>
                <li>Enseñamos contenidos (mas o menos) según formato de la pantalla (ancho) ver el modelo utilizado en el resto de componentes de listados de esta misma aplicación  </li>
                <li>Los contenidos están limitados a los que os paso en el ppt</li>
                <li>Todos se pueden obtener directamente de los datos de la api disponible</li>
                <li>No tiene ningun comportamiento especial, se llega desde el proyecto a través del link (que hoy apunta al rac en lugar de a una ruta interna) </li>
                <li>Se sale solo volviendo atrás (al proyecto desde el que llamamos </li>
                <li>Recupera los datos de la ruta de servidor oferta/:OFE_ID (por ejemplo oferta/023.00.107970)</li>
                <li>OJO, es una ruta en node con validación de usuario, si quereis ver contenido hay que levantar servicio en local y utilizar 
                    la de debugado </li>
                <pre> cd proyectos.ges.services && node app.js #levanta server en puerto 3000;
                    La llamada 'http://localhost:3000/oferta/023.00.107970/raul.sanchez(USUARIO CON PERMISOS SUFICIENTES)' 
                    os enseñará el contenido sin necesidad de valicación 
                </pre>


            </ul>

        </ion-content>
    </ion-page>
</template>

<script>
    import {    
            IonPage, IonContent
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "OfertaDetail",
        components: {  
            IonPage,  IonContent
        },

        methods: {

        }
})
</script>

<style scoped>
    .negrita { font-weight: bold;}

</style>