import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import eventBus from "@/event-bus";
import {globalFilters} from './utilities/filters'
import {setInitDates, getInitFilters} from '@/utilities/services/init_services'

import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import DropDown from 'primevue/dropdown';
import Knob from 'primevue/knob';
import FileUpload from 'primevue/fileupload';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/** Importamos los css de primevue */
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';



import axios from 'axios';
import { setupCache } from 'axios-cache-adapter'
import firebase from 'firebase/app';
import 'firebase/auth';


/* Theme variables */
import './theme/variables.css';
import './registerServiceWorker'

/** Customizaciones datatable -han de ser globales- */


window.miconsola =   console.log 
window.fake_console = function() {void(0)}; 


const cache = setupCache({ maxAge: 0 * 60 * 1000 })
 

 //axios.defaults.baseURL = 'http://localhost:3000'
 axios.defaults.baseURL = 'https://proyectos.ges.services'

 axios.defaults.adapter = cache.adapter 
axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
axios.mode = "no-cors"

/** FB config*/
firebase.initializeApp({
  apiKey: "AIzaSyAzwMsPdcrIbVEvqrqopkjpOLG2Vm3PjGA",
  authDomain: "appgescom.firebaseapp.com",
  projectId: "appgescom",
  storageBucket: "appgescom.appspot.com",
  messagingSenderId: "728244704694",
  appId: "1:728244704694:web:ced2e56799f4abb42a1d17",
  measurementId: "G-KX4GNXBZ0Q",
  databaseURL: "https://appgescom.firebaseio.com"
});

/** 
 * Logica de la aplicación 
 * El router no se inicializa hasta que tenemos 
 * un cambio de AuthState en firebase
 * Sino monta las paginas antes de tener el usuario 
 * */

let app = createApp(App)
          .use(IonicVue)
          .use(PrimeVue)
          .use(router);

app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('InputText',InputText);
app.component('MultiSelect',MultiSelect);
app.component('Button',Button);
app.component('DropDown',DropDown);
app.component('Knob', Knob);
app.component('FileUpload',FileUpload);
app.component('InputNumber',InputNumber);
app.component('Card',Card);

app.component('Calendar',Calendar);


/**
 * Variables globales 
 */
var self = app.config.globalProperties
self.$http = axios
self.$current_user = undefined
self.$current_user_name = undefined
self.$device = undefined
self.$filters = globalFilters
self.$ges = undefined 
self.$editor = undefined 
self.$multi = undefined
self.$pdv_root = undefined
self.$utype = undefined
self.$deeplink = undefined 


/**
 * Variable global para acceder a Firebase 
 * desde el conjunto de la aplicación 
 * sino acaba intentando instanciarla varias veces
 */
self.$firebase = firebase
window.fake_console('main ','RUTAS : '+JSON.stringify(self.$router.routes))
/** Logica de la aplicación */
firebase.auth().onAuthStateChanged(async (usr) => {
   /** Aqui el nombre del usuario esta a primer nivel, 
    *  Firebase devuelve el objetvo 'user' 
    */
   window.fake_console('main ',usr)
   window.fake_console('main ','paso por la función onAuthStateChanged')
   if(usr!=null) {
    /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
    window.fake_console('main ','if/main 110: '+usr.displayName)
    firebase.auth().currentUser.getIdToken(true).then(async (idToken) =>{
      self.$http.defaults.headers['Authorization'] = 'Bearer '+idToken
      await setInitDates()
      await getInitFilters()
      const supRequest = await self.$http.get('/check')
      const roles = supRequest.data
      roles['who'] = usr.displayName
      window.fake_console('main ',roles) 
      eventBus().emitter.emit('user-logged',roles);   
      window.fake_console('main ','RUTAS 133: '+JSON.stringify(self.$router.routes))
      //self.$router.replace('/resumen/pipeline');
      window.fake_console(self.$deeplink)
      self.$router.replace(self.$deeplink !=undefined ? self.$deeplink : '/resumen/pipeline') 
    })
   }
   else {
     /** no hay usuario, pasamos por login */
     window.fake_console('main ','No hay usuario, pasamos por pagina de login')
     self.$router.replace('/login')
   }
});

router.isReady().then(() => {
  window.fake_console('main ','App montada')
  app.mount('#app');
  
});



eventBus().emitter.on('device',(dev)=>{ self.$device=dev});
eventBus().emitter.on('deeplink',(dl)=>{ 
  window.fake_console('en el evento '+dl)
  self.$deeplink=dl
});


/** Variables globales que se utilizan en el comportamiento de la app */
eventBus().emitter.on('user-logged',(roles)=>{ 
  window.fake_console(roles)
  self.$current_user = roles.usr
  self.$current_user_name = roles.uname
  self.$ges = roles.ges
  self.$editor = roles.editor
  self.$multi = roles.multi === true ? 'multi' : 'single'
  self.$pdv_root = roles.pdv_root
  self.$utype = roles.tp
  self.$mispdvs = roles.pdvs 
  self.$rol = roles.rol
  self.$tg = roles.tg

  window.fake_console('main ',roles)
});