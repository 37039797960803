<template>
  <ion-page>      
      <cabecera-component 
            titulo="3. Define el bloque de proyecto   " 
            color="primary"
        />
        <ion-content class="ion-padding" v-if="$editor">
                <div class="crear">
                    <ion-toolbar>
                            <ion-title class="completo">
                                <small>Pon nombre a la partida del proyecto</small>    
                            </ion-title>
                    </ion-toolbar> 
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-field p-col-12">
                                    <div class="p-col">
                                        <InputText v-model="bk_name" id="obname"
                                            type="text" placeholder="Nombre de la nueva partida del proyecto, minimo 10 letras" 
                                            :class="(bk_name!=undefined && bk_name.length<10) ? 'p-invalid' : ''"
                                            />
                                        <label class="etiqueta-error" for="obname" v-if="(bk_name!=undefined && bk_name.length<10)" >Como minimo 10 caracteres</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="elegir" v-if="bk_name">
                    <ion-toolbar>
                        <ion-title class="completo">
                            <small>Elige la marca principal</small>    
                        </ion-title>
                    </ion-toolbar> 
                    <DropDown v-model="marca_sel" :options="marcas" optionLabel="k" class="selector"
                        :filter="true"   v-bind:key="'sel_brand'"  
                        dataKey="_id"
                        optionValue="_id"
                        @hide="filtra_pline"
                        placeholder="Elige marca «ganadora»" />
                </div>

                <div class="elegir" v-if="filteredPlines">
                <ion-toolbar>
                        <ion-title class="completo">
                            <small>Selecciona un Product Line </small>   
                        </ion-title> 
                </ion-toolbar> 
                    <DropDown v-model="pline_sel" :options="filteredPlines" optionLabel="k" class="selector"
                        :filter="true"   v-bind:key="'sel_pline'"  
                        dataKey="_id"
                        optionValue="_id"
                        @hide="selectPline($event)"
                        placeholder="Elige la pline principal de la partida" />
                </div>

                <div class="elegir" v-if="marcas_alternativas">
                <ion-toolbar>
                    <ion-title class="completo"><small>Detalla marcas alternativas</small></ion-title>
                </ion-toolbar> 
                        <MultiSelect  v-model="marcas_alt_sel" :options="marcas_alternativas" optionLabel="k" 
                            :filter="true"   
                            v-bind:key="'marcas_alternativas_selector'" 
                            dataKey="_id"
                            optionValue="_id"
                            placeholder="Seleccionar alternativas" display="comma"
                            />
                </div>

                <div class="elegir" v-if="marcas_alt_sel">
                    <ion-toolbar>
                        <ion-title class="completo">
                            <small>Oferta principal G2K</small>    
                        </ion-title>
                    </ion-toolbar> 
                    <DropDown v-model="oferta_sel" :options="ofertas" optionLabel="tx" class="selector"
                        :filter="true"   v-bind:key="'sel_oferta'"  
                        dataKey="_id"
                        optionValue="_id"
                        placeholder="Elige oferta" />
                </div>

                <ion-row v-if="marcas_alt_sel" class="elegir" >
                    <ion-col size="26">
                        <ion-toolbar>
                            <ion-title class="completo"><small>Venta potencial?</small></ion-title>
                        </ion-toolbar> 
                        <ion-note color="danger"><small>Importe en € sin decimales</small></ion-note>
                            <InputNumber  
                                mode="decimal" locale="de-DE" 
                                :maxFractionDigits="0"
                                showButtons buttonLayout="horizontal"
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" 
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" 
                                :step="1000" :min="5000" :max="3000000"
                                placeholder="Minimo 5.000€"
                                v-model="venta"
                            />
                            <ion-note   style="font-weight:500 !important;"    color="danger" v-if="venta<5000 || venta>3000000">El importe debe estar entre 5.000 y 3 Millones de Euros.</ion-note>
                    </ion-col>
                        
                    <ion-col size="22">
                        <ion-toolbar>
                            <ion-title class="completo"><small>Fecha revision</small></ion-title>
                        </ion-toolbar> 
                            <ion-note color="danger"><small>Se borrará 30 días después</small></ion-note>
                            <Calendar  v-model="review_date" dateFormat="dd.mm.yy" />
                    </ion-col>
                </ion-row>
                <ion-button style="float:right" color="primary" @click="GrabarProyecto()"  :disabled="!Validar" size="small">Grabar</ion-button>                    

        </ion-content>

        <!-- Si no tiene cartera de clietnes,  no puede ver nada -->
        <ion-content  class="ion-padding" v-else>
            <h2> 
                Parece que no tienes cartera de clientes asignada....
                No puedes ver esta pagina 
            </h2>
        </ion-content>
    </ion-page>
</template>


<script>
    import { IonContent, IonPage,IonButton,IonCol,IonNote,
            IonToolbar,IonTitle, IonRow, alertController
        } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import {intersection, reduce, omit, keys, extend,map } from 'underscore';
    import { Plugins } from '@capacitor/core';
    import {initcap} from '@/utilities/filters.js';
    const { Storage } = Plugins;
    export default defineComponent({
        name: 'DefineProjectBlock',
        components: { 
            IonContent, IonPage,IonButton,IonCol,IonRow, IonNote,
            IonToolbar,IonTitle, CabeceraComponent
            }, 
        props :['proyecto_data'],
        computed: {
                Validar : function(){
                    const x = [
                        this.bk_name, 
                        this.venta  , 
                        this.venta>=5000 && this.venta<=3000000, 
                        (this.review_date == undefined || typeof(this.review_date)!='object') ? undefined : true, 
                        this.marca_sel , 
                        this.pline_sel , 
                        this.marcas_alt_sel==undefined ? undefined : this.marcas_alt_sel.length == 0 ? undefined : true , 
                        this.oferta_sel 
                    ]
                    window.fake_console(x)
                    return x.every(y=>y!=undefined)
                }
        },
        data() {
            return {
                bk_name: undefined, 
                marcas : undefined, 
                plines: undefined , 
                marcas_pline: undefined,
                marca_sel : undefined , 
                filteredPlines : undefined, 
                pline_sel: undefined, 
                marcas_alternativas : undefined, 
                marcas_alt_sel : undefined ,
                oferta_sel: undefined, 
                review_date: undefined, 
                venta: undefined, 
                ofertas : undefined , 
                project_data : undefined
            }
        }, 
        async mounted() {
            if (this.$editor != true || this.proyecto_data == undefined) {
                alert('No puedes ver esta pagina, te redirigimos a tu home ')
                this.$router.replace('/resumen/pipeline')
            }
            this.project_data = JSON.parse(this.proyecto_data)
            /** Recuperamos las marcas posibles y las relaciones entre marcas y plines */
            this.getOfertas()
            const fl =  await Storage.get({ key: 'proyectos:filtros' });
            const filtros = JSON.parse(fl.value) 
            window.fake_console(filtros)
            this.marcas = map(filtros.marcas, (x)=> {x['_id']=x['v'][0]; return x })
            const mpl =  await Storage.get({ key: 'proyectos:marcas_pline' });
            const d = JSON.parse(mpl.value)
            this.plines = map(d.plines, (x)=> {x['_id']=x['l'][0]; return x })
            window.fake_console(this.plines)
            this.marcas_pline = d.marcas_pline
            window.fake_console(this.marcas_pline)
            /** Solo puedes verla si eres supervisor, sino te redirige a  */

        },
        methods : {
            async getOfertas() {
                window.fake_console(`/ofertas/${this.project_data.c}`)
                const ofd = await this.$http(`/ofertas/${this.project_data.c}`)
                this.ofertas = map(ofd.data , (o)=> {
                        o._id = `${o.p}.${o.s}.${o.np}`; 
                        o.tx = `${o.p}.${o.np} - ${o.pc != undefined ? initcap(o.pc) : o.n+' (No detallado)' }`; 
                        return o;
                    }) 
                this.ofertas.unshift({'_id': 'sin_oferta', 'tx': 'No hay oferta'})
                window.fake_console(this.ofertas)   
            }, 
            
            filtra_pline(){
                /** Paso posterior a la selección de la marca principal */
                this.filteredPlines = undefined 
                this.marcas_alternativas = undefined
                this.marcas_alt_sel = undefined 
                this.pline_sel = undefined 
                window.fake_console(this.marca_sel)
                this.filteredPlines = this.plines.filter( (p)=> {
                    return intersection(p.l, this.marcas_pline[this.marca_sel]).length>0
                })
                /** Si no es significativa marcamos como sin categoria, 
                 *  en el siguiente paso dejaremos elegir cualquier marca  */
                if (this.filteredPlines.length==0) {
                    this.filteredPlines= this.plines.filter(p=>p.l[0]=='9900A')
                    this.pline_sel = '9900A'
                    this.selectPline()   
                }
            },
            selectPline() {
                this.marcas_alternativas= undefined 
                this.marcas_alt_sel = undefined
                const pline = this.pline_sel;
                const marca = this.marca_sel;
                window.fake_console(pline, marca, this.marcas_pline)
                let malt
                if (this.filteredPlines.length == 1 && this.filteredPlines[0]['l'][0]=='9900A')
                {
                    window.fake_console('Estoy en el caso de marca unica')
                    /** Si es una marca 'menor' y no hay pline, dejamos ver todas la marcas  */
                    window.fake_console(this.marcas_pline, marca)
                    malt = keys(omit(this.marcas_pline, marca))
                    window.fake_console(malt)
                }
                else 
                {
                    malt = reduce(this.marcas_pline, (m,v,f)=> {
                    if ( v.indexOf(pline) !=-1 && f!=marca ) {m.push(f)}
                    return m;
                    },[]);
                }
                this.marcas_alternativas = this.marcas.filter((v)=> {return intersection(v.v, malt).length>0 })
            },

            async GrabarProyecto(){
                window.fake_console(this.project_data)
                const pro = {
                    bk: this.bk_name, 
                    v: this.venta, 
                    t : this.review_date.getTime(), 
                    l : this.pline_sel, 
                    mp : this.marca_sel, 
                    ma :  this.marcas_alt_sel, 
                    of: this.oferta_sel, 
                    fc: Date.now()
                }
                const post = await this.$http.post('/proyecto',{'proyecto': extend(this.project_data, pro)})
                    if (post.data.status =='error') {
                        alert('Algo no hay ido bien, revisa los datos...')
                    }
                    else {
                        
                        const alerta = await alertController
                            .create({
                                message: 'Nuevo proyecto informado',    
                                mode : "md",
                                buttons: [
                                    {
                                    text: 'Salir',
                                    handler: () => { this.$router.replace('/resumen/pipeline')},
                                    },
                                ]
                            })
                            return alerta.present();     

                    }
            }, 
            cancelar(){
                this.$router.replace('/resumen/pipeline')
            },
            goBack(){
                this.$router.go(-1)
            }
        }
        });
</script>

<style scoped>
    .etiqueta-error {
        color:var(--ion-color-darkdanger);
        font-style:italic;
        padding-left: 1rem;
    }
    ion-toolbar {
        --min-height: 36px;
    }
    ion-row{ width: 100%; }
    ion-col{ padding: 2px !important;  }
    
    ion-button{
        float:right;
        width: 8rem; 
        height: 2.2rem;
        --border-radius: 6px;

    }
    div.crear, div.elegir, ion-row.elegir {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
        padding-top: 0px;
        border: 1px solid var(--ion-color-light);
        margin-top:8px;
    }
    ion-title.completo {
        padding-left: 16px; 
        padding-right: 16px; 
        text-align: left;
    }
    .p-inputnumber {
        width: 100% !important;
    }
    .p-inputnumber-input{
        text-align: right;
    }
</style>
