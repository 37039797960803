<template> 

      <ion-menu content-id="main-content" type="overlay" mode="md" v-bind:key="ges">
        <ion-content>
          <ion-list id="inbox-list">

            <ion-list-header lines="none" class="sin-borde">Obras y Proyectos </ion-list-header>
            <ion-item @click="logout()">
                  <ion-label class="nombre">{{who}}</ion-label>
                  <ion-button color="darkdanger" slot="end">Salir</ion-button>
            </ion-item>

              <ion-item class="nombre-secundario" lines="none">
                  <ion-label class="nombre-secundario">
                    Conectado como: {{usr_name}}
                  </ion-label>
              </ion-item>


            <!-- Proyectos -->
            <div class="contenedor-menu" @click="proyectos_detail = !proyectos_detail">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                  <!-- omitimos la apertura y cierre de la flecha, siempre abierto-->
                  <!--ion-icon :md="proyectos_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> -->
                  <ion-icon :md="caretForwardOutline" class="titulo"></ion-icon>  
                   Proyectos actuales 
                </ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasProyectos" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasProyectos.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="proyectos_detail||!proyectos_detail"
                  >
                    <ion-icon :md="p.mdIcon" class="icono-titulo" :color="p.destacar ==true ? 'darkdanger' : ''" >
                    </ion-icon>
                    <ion-label class="elemento-menu" :color="p.destacar ==true ? 'darkdanger' : ''">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </div>

 
 
            <!-- Oportunidades -->
            <div class="contenedor-menu" @click="oportunidades_detail = !oportunidades_detail">
               <ion-toolbar  color="default" class="block-title">
                <ion-title class="ion-text-start subtitulo">
                <!-- omitimos la apertura y cierre de la flecha, siempre abierto-->
                <!--<ion-icon :md="oportunidades_detail ? caretDownOutline : caretForwardOutline" class="titulo"></ion-icon> -->
                <ion-icon :md="caretForwardOutline" class="titulo"></ion-icon> 
                  Oportunidades</ion-title>
              </ion-toolbar>

              <ion-menu-toggle auto-hide="false" v-for="(p, i) in PaginasOportunidades" :key="i">
                <ion-item @click="selectedIndex = p.clave" router-direction="root" 
                  :router-link="p.url" detail="false" class="hydrated" 
                  :lines="i === (PaginasOportunidades.length-1) ? 'none':'full' "
                  :class="{ selected: selectedIndex === p.clave}"
                  id="bloque"
                  v-if="(oportunidades_detail||!oportunidades_detail) && showUploader(p.url)"
                  >
                    <ion-icon :md="p.mdIcon" class="icono-titulo" :color="p.destacar ==true ? 'darkdanger' : ''" >
                    </ion-icon>
                    <ion-label class="elemento-menu" :color="p.destacar ==true ? 'darkdanger' : ''">{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            
            </div>

           </ion-list>
  
        </ion-content>
      </ion-menu>
    
</template>

<script>
import { 
  IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, 
  IonMenu, IonMenuToggle, menuController , 
  IonToolbar,IonTitle 
  } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { statsChartOutline, peopleOutline,listCircleOutline, shareOutline, 
         appsOutline, list, people,speedometerOutline,searchCircleOutline, 
         caretDownOutline, caretForwardOutline, alertCircleOutline, handLeft, 
         nuclearOutline,addCircleOutline,calendarOutline,
         star
 } from 'ionicons/icons';
import eventBus from "@/event-bus";

export default defineComponent({
  name: 'App',
  components: {
    IonButton, 
    IonContent,  IonIcon,  IonItem, IonLabel, IonList,IonListHeader,  IonMenu, 
    IonMenuToggle, 
    IonToolbar,IonTitle 
  },
  mounted() {
        window.fake_console('Menu ','Menu mounted')
        eventBus().emitter.on('user-logged', (dat) => {
          window.fake_console('Menu ','recibo user-logged en Menu  '+JSON.stringify(dat))
          this.who= dat.who
          this.users = dat.users
          this.usr_name= dat.uname
          this.ges = dat.ges
          this.mispdvs = 'all' 
        });

  }, 
  data() {
    return {
      usr_name:undefined, 
      who: undefined, 
      users: undefined, 
      proyectos_detail: true, 
      oportunidades_detail: true, 
      stock_detail: false , 
      ajustes_detail : false, 
      ges : false, 
      mispdvs : undefined 
    }
  },
  methods: {
    async logout() { 
       this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.NONE)
       this.$firebase.auth().signOut()
        .then(function() {
          alert('vas a cerrar la aplicación!!')
          menuController.close()
          window.close()
        })
        .catch(function(error) {
          window.fake_console('Menu ',JSON.stringify(error))
          alert('algo ha fallado....')
          });
    }, 
    showUploader(url){
       return url!=='/subir/oportunidades' ? true : this.$rol==51 // this.$supervisor 
    }
  },

  setup() {
    window.fake_console('Menu setup')
    const selectedIndex = ref (0);
    const PaginasProyectos = [           
      /**
       * Pagina resumen donde recuperamos el resumen por pdv/marca 
       * Damos una visión sobre el total actual y 
       * la calidad media del stock actual 
       */
      {
        title: 'Resumen de Proyectos',
        url: '/resumen/pipeline',
        clave : 'resumen_pipeline',
        mdIcon: speedometerOutline
      },
            {
        title: 'El último de la fila...',
        url: '/comerciales/zero',
        clave : 'comerciales_zero',
        mdIcon: nuclearOutline, 
        destacar: true
      },
      { 
        title: 'Crear nuevo proyecto',
        url: '/new/project/customer',
        //url: '/nuevo/proyecto',
        clave : 'new_project',
        mdIcon: addCircleOutline
      },
      { 
        title: 'Listado de proyectos',
        url: '/listado/pipeline',
        clave : 'pipeline_list',
        mdIcon: listCircleOutline
      },
      { 
        title: 'Caducan en breve...',
        url: '/listado/to_expire',
        clave : 'pipeline_overdue_list',
        mdIcon: calendarOutline, 
        destacar: true
      },
      {
        title: 'Nuevos ultimos 7 dias',
        url: '/listado/new_projects',
        clave : 'pipeline_new_list',
        mdIcon: list
      },
           
    ]



    const PaginasOportunidades = [           

      { 
        title: 'Oportunidades Construdata',
        url: '/listado/oportunidades',
        clave : 'leads_list',
        mdIcon: listCircleOutline
      },
      {
        title: 'Actualización oportunidades',
        url: '/subir/oportunidades',
        clave : 'upload_oportunidades',
        mdIcon: list
      }
     

    ]

    return {  statsChartOutline, peopleOutline,listCircleOutline, shareOutline, 
              appsOutline, list, people,searchCircleOutline,caretDownOutline, caretForwardOutline, 
              nuclearOutline,addCircleOutline,calendarOutline,
              alertCircleOutline, handLeft, selectedIndex,
              PaginasProyectos,PaginasOportunidades,star
    }
  }
});
</script>

<style scoped>
  ion-icon.titulo {
    font-size: 20px !important;
    vertical-align: bottom;
  }
  .nombre {
    color: var(--ion-color-primary);
    font-weight: bold;
  }
  .nombre-secundario {
    color: var(--ion-color-primary);
    font-style: italic;
    font-weight: bold;
    border-bottom:none;
    font-size: 12px !important; 

  }
  ion-label { 
    padding-left : 8px;
    font-size: 14px !important;
    }
  
  ion-item.ultima {
    border-style : hidden 0px !important ; 
  }

  div.contenedor-menu {
    margin-top: 6px !important;
    margin-left: 12px !important ;
    margin-right: 12px !important ;
    padding-bottom: 6px !important; 
    border: 2px solid var(--ion-color-light);
  }
  ion-toolbar.block-title {
    --min-height:38px !important; 
    margin-bottom: 6px !important;
    background-color: var(--ion-color-light);
  }
  .subtitulo {
    color: var(--ion-color-dark-tint);
    font-size: 16px !important;
    font-weight: bold;
    padding-bottom: 4px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;    
    vertical-align: bottom;
  }
  ion-icon.icono-titulo {
    font-size:16px !important;
  }
  ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }
  
  ion-menu ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }

  ion-menu ion-list {
    padding: 6px 0;
  }

  ion-menu ion-list#inbox-list  ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 32px;
  }

  ion-list-header.sin-borde{
    border: none !important;
  }

  ion-menu ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
    --min-height: 40px;
  }

  ion-menu ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
  }

  ion-menu ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-menu ion-item ion-icon {
    color: #616e7e;
  }

  ion-menu ion-item ion-label {
    font-weight: 400;
  }

  ion-item.selected {
    --color: var(--ion-color-primary);
  }

  #bloque {
    margin-left: 8px;
    margin-right: 8px !important;
    font-size: 14px !important;
    --min-height: 28px; 
  }
  ion-label.elemento-menu{
    margin-top: 6px !important; 
    margin-bottom: 6px !important; 
  }

</style>