<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="danger"/>
        <ion-content class="ion-padding" v-if="device" >  

            <ion-row class="ion-justify-content-left ion-align-items-top selectores" v-if="datos && datos.length>paginador" >
                    <ion-col >
                        <div class="p-input-icon-right">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 3 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                    </ion-col>
            </ion-row>

            
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="darkdanger" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
                <Card v-if="!loading && datos.length==0">
                    <template #title>
                        Enhorabuena!!
                    </template>
                    <template #content>
                        Todas las personas con cartera de tu equipo tienen al menos un proyecto. 
                    </template>
                </Card>

                <DataTable :value="filtrados" :rows="filas" 
                    :paginator="paginador"
                    paginatorTemplate="RowsPerPageDropdown  FirstPageLink PrevPageLink  NextPageLink LastPageLink CurrentPageReport"
                    :rowsPerPageOptions="[10,25,50,100]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    v-if="!loading && datos.length>0"
                    class="p-datatable-oportunidades" 
                    v-bind:key="device.tipo && device.ancho"
                    scrollHeight="flex"
                    scrollDirection="vertical" 
                    :scrollable="true" 
                    removableSort
                    ref="datatable"
                     >
                     <template #header>
                        <div class="table-header">
                            <ion-col size="44">
                               Comerciales sin proyectos (0 proyectos)
                            </ion-col>
                            <ion-col>
                                <Button icon="pi pi-file-excel"  style="float:right !important;"
                                    @click="exportCSV($event)"
                                    class="p-button-rounded p-button-success" 
                                />
                            </ion-col>

                        </div>
                </template>
                <template #loading>
                    Cargando datos. Por favor, espera.
                </template>

                    <Column v-for="(c, index) of columnas[device.tipo]" :field="c" 
                        :header="nombres[c]" :key="c + '_' + index" 
                        :sortable="paginador"
                        :headerStyle="anchos[c]" 
                        :bodyStyle="anchos[c]" 

                        >
                        <template #body="slotProps" >
                          <div  class=" izquierda" v-if="txtCols(c)">
                              {{slotProps.data[c]}}
                          </div>
                          <div class="derecha" v-else-if="c =='n'">
                               {{NumParser(slotProps.data[c])}}
                          </div>
                        </template>
                    </Column>
                </DataTable>
           <!-- </ion-row>-->
        </ion-content>
        


    </ion-page>
</template>

<script>
    import { IonPage, IonContent,   IonRow,IonCol,  
            IonSpinner
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import eventBus from "@/event-bus";
    import { Plugins } from '@capacitor/core';
    const { Storage } = Plugins;
    import { closeCircle } from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    import { export2Xls } from '@/utilities/services/xls_export';
    export default defineComponent({
            name : "ComercialesZero",
            components: { 
                IonPage, IonContent, IonRow,IonCol, CabeceraComponent, 
                IonSpinner
            } , 
            computed :{
                fontSize () { 
                    window.fake_console('ComercialesZero',`--texto-size : ${12+(this.device.tamanyo)}px`)
                    return `--texto-size : ${12+(this.device.tamanyo)}px;`
                }, 
                anchos () {
                    const base = {
                            "sm" : {"px" : 15,"en" : 60,"n" : 25},
                            "md" : {"pxn" : 25,"en" : 45, "fn": 15, "n" : 15},
                            "lg" : {"pxn" :  15,"en" : 45, "fn": 20, "n" : 20},
                            "xl" : {"pxn" :  15,"en" : 45, "fn": 20, "n" : 20}
                            }
                    /** Nos aseguramos que las definiciones de columnas sumen 100, sino aplicamos proporcionalidad  */
                    //const factores = _.reduce(base, (m,v,k)=> {m[k]=_.chain(v).values().reduce((mx,x)=>{return mx+=(x/100)},0).value() ;return m } , {}) 
                    const factor = _.reduce(_.values(base[this.device.tipo]), (m,v)=> {return m+=(v/100)},0)
                    window.fake_console(factor)
                    const resultado = _.reduce(base[this.device.tipo], (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    window.fake_console(this.device.tipo, this.device.ancho,  factor, resultado)
                    return resultado
                    },
            },
            data() {    
                return {
                    titulo_pag:'Carteras sin ningun proyecto',
                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle, 
                    columnas :{
                        'sm':['px','en','fn','n'],
                        'md':['pxn','en','fn','n'],
                        'lg':['pxn','en','fn','n'],
                        'xl' :['pxn','en','fn','n']
                    }, 
                    nombres: {
                        'px':'Pdv',
                        'pxn' : 'Nombre Pdv', 
                        'en' : 'Comercial', 
                        'fn' : 'Actividad',
                        'n' : 'Nifs Cartera'
                    },
                    
                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    filas: 10 , 
                    paginador : true, 

                    /** Variable que controla el spinner de carga  */
                    loading: true, 

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde cargamos los datos de las peticiones xhttp tal como vienen 
                     */
                    datos: undefined ,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    
                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                }
            },  
            async updated(){
                window.fake_console('ComercialesZero','en el update 245')
                const route = useRoute();
                window.fake_console('ComercialesZero',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('ComercialesZero','llamo al update ')
                    this.ruta = route.fullPath
                    this.procesoInicial()

                }         
                else {
                    window.fake_console('ComercialesZero','NO llamo al update')
                }       
            },
            async mounted() {
                this.loading = true;
                window.fake_console('ComercialesZero','en el mount 273')
                const route = useRoute();
                window.fake_console('ComercialesZero',this.ruta, route.path, route.query)
                this.ruta = route.fullPath
                window.fake_console('ComercialesZero',route.path, route.query)
                this.procesoInicial()

            }, 
            methods :{
                AnchoCol(x) { return `width: ${(this.device.ancho-32)*x/100}px !important`}, 
                /** Lo cambiaremos por un simple dialog, para ver 4 datos no hace falta más  */
                txtCols(c) {
                    return ['px','pxn','e','en', 'fn'].indexOf(c)!=-1 
                },
                NumParser(n) {
                    return [undefined, null, 0].indexOf(n)!=-1 ? '-' : parseInt(n).toLocaleString('de-DE')
                },
                async procesoInicial() {
                    this.loading = true;
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    window.fake_console('ComercialesZero',Date.now() +'filtros montados ')
                    this.fetchData()
                    window.fake_console('ComercialesZero',Date.now() +'llamada a datos realizada ')
                }, 
                
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.filtrar()}, 250)
                }, 
                async fetchData(){ 
                    this.loading = true
                    const act = await Storage.get({ key: `proyectos:comerciales_activos`});
                    const activos = JSON.parse(act.value)
                    const lbse = await Storage.get({ key: 'proyectos:labels:e' });
                    const exts_labels= JSON.parse(lbse.value)
                    window.fake_console(exts_labels)
                    const lbspx = await Storage.get({ key: 'proyectos:labels:px' });
                    const px_labels= JSON.parse(lbspx.value)
                    window.fake_console(px_labels)
                    const td = await this.$http.get('/sales_force')
                    const todos = td.data.filter(f=>activos.indexOf(f.e)==-1).map(y=>{
                        y['en']=exts_labels[y['e']]; 
                        y['pxn']=px_labels[y['px']]; 
                        y['fn'] = y['f']=='E' ? 'Electrico' : 'Fluids'
                        return y;
                        })
                    this.datos = todos
                    window.fake_console(this.datos)
                    this.filtrar()
                    this.loading = false
                }, 
                
                async filtrar() {
                    window.fake_console('ComercialesZero',JSON.stringify(this.SelectedFilters))
                    let filtrados = this.datos
                    if (this.termino!=undefined && this.termino.length>=3){
                        filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(this.termino)!=-1})
                    }
                    const limite = this.limite!=undefined ? this.filas : filtrados.length
                    filtrados = _.sortBy(filtrados, (e)=>-e['eur']).slice(0,limite)
                    this.filtrados = filtrados
                    window.fake_console('ComercialesZero',this.filtrados.length, this.filas, this.paginador)
                    if (this.filtrados.length <=this.filas) { this.paginador = false}
                        else { this.paginador = true}
                    window.fake_console('ComercialesZero',this.filtrados.length, this.filas, this.paginador)
                    this.loading = false
                },
                                
                exportCSV() {
                 const nombre = this.titulo_pag.replace(/ /g,'.').replace(/[()]/g,'').toLowerCase()
                 export2Xls(this.$refs.datatable,{},nombre)
                }
            }
            
})
</script>

<style scoped>
    ion-row.spin{
        min-height: 300px;
    }
    spin {
        height: 75%
    }
    ion-spinner {
        width:75px; 
        height:75px;
        display: block;
        margin: auto;      
    }
    ion-badge {
        margin-top: 6px; 
        margin-right:6px; 
        padding-top: 0px;
        padding-right: 12px; 
        padding-left: 12px; 
        padding-bottom: 4px; 
    }
    ion-icon.badge {
        padding-top: 4px; 
        font-size:1.25rem; 
    }
    
    ion-row.selectores {
        border: 1px solid var(--ion-color-light);
    }
    .derecha {
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        text-align: left !important;
        
    }
    .p-card {
        width: 90%;
        margin : 20px auto;
    }
</style>

/** Todos los estilos relacionados con PrimeVue Datatable los declaramos como globales, 
    sino no  funcionan */
