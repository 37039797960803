<template>
    <ion-col>
        <ion-card >
            <ion-card-header v-if="titulo.length>0 && titulo!=undefined" class="head">
                <ion-card-subtitle size="small"
                    v-if="titulo.length>0 && titulo!=undefined">
                    {{titulo}}
                     <ion-row class=" mini-lista titulin">
                        <ion-col size="10" offset="28"># Proyect.</ion-col>
                        <ion-col size="10">Valor K€</ion-col>
                    </ion-row>
                </ion-card-subtitle>
     
            </ion-card-header>

            <ion-card-content  v-if="datos">
                <ion-row v-for="(item,index) in datos.slice(0,limit)"  v-bind:key="index" @click="irListado(item)" class="bordecito" >
                        <ion-col size="30" class=" bordecito ion-text-nowrap  mini-lista nombre_cortado ion-text-start">{{ labels==undefined ? item._id : labels[item._id]  }}</ion-col>
                        <ion-col size="8" class=" bordecito mini-lista ion-text-end peque"> {{ $filters.Entero(item['ct'])}}</ion-col>
                        <ion-col size="10" class=" bordecito mini-lista ion-text-end peque"> {{ $filters.Moneda(item['vt']/1000)}}</ion-col>
                </ion-row>
                <ion-row v-for="(item,index) in lista"  v-bind:key="index+limit" @click="void(0)" >
                    <br/>
                </ion-row>
            </ion-card-content>

            <ion-card-header class ="foot" v-if="suma">
                <ion-card-subtitle size="small"  >
                {{ $filters.Moneda(suma/1000)}}
                </ion-card-subtitle>
            </ion-card-header>

        </ion-card>
    </ion-col>
</template>
    

<script>
    import {    IonCol,IonRow,
                IonCard, IonCardHeader, 
                IonCardSubtitle,
                IonCardContent
            } from '@ionic/vue';

    import { defineComponent } from 'vue';
    import {chain, map, range } from 'underscore';

    export default defineComponent({
            name : "TarjetaListaPipelineGrande",
            components :  {    
                IonCol,IonCard, IonCardHeader, IonRow,
                IonCardSubtitle,
                IonCardContent 
            }, 
            mounted() {
                window.fake_console('TarjetaListaPipelineGrande ',this.dat)
                this.datos = chain(this.dat)
                    .filter((e)=> { return this.asc===true ? e[this.caso]>0 : e[this.caso]<0 } )
                    .value()
                    .slice(this.asc===true ? 0 : -this.limit , this.asc===true ? this.limit: Infinity)
                if (this.asc != true) {this.datos.reverse()}
                window.fake_console('TarjetaListaPipelineGrande ',this.datos)
            }, 
            methods: {
                async irListado(item) {
                    window.fake_console('TarjetaListaPipelineGrande ',`/listado/pipeline/${this.clase}/${item._id}`)
                    this.$router.push(`/listado/pipeline/${this.clase}/${item._id}`)
                },
            },
            computed :{
                limit : function(){
                    return  this.limite == undefined ? 5 : this.limite
                },
                lista: function () {
                    if (this.datos.length<this.limit )
                    { return map(range(this.datos.length,this.limit), (e)=> {return e })}
                    else { return []}
                }, 
                suma : function () {
                    const signo =this.asc === true ? Math.max : Math.min
                    return chain(this.datos).pluck(this.caso).reduce((m,v)=>{return m+=signo(0,v) },0).value() 
                    }
            },     
            props: ['titulo','dat', 'limite','asc', 'clase', 'labels','propiedad'], 
            data() {
                return {
                    datos:undefined, 
                    caso : {'valor':'vt', 'proyectos':'ct'}[this.propiedad]
                } 
                
            }
    })
</script>

<style scoped>
        ion-col { padding: 4px;}
        ion-card {
            margin:6px  2px !important;
        }
        ion-card-content {
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 4px; 
            padding-right: 4px;
            text-align: right; 
            font-size:smaller; 
        }
        
        ion-card-subtitle{
            text-align: center;
        }
        ion-card-header.head {
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 4px ; 
            padding-bottom : 4px; 
            border-bottom: 2px solid var(--ion-color-light);
        }
        ion-card-header.foot {
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 4px ; 
            padding-bottom : 2px; 
            border-top: 2px solid var(--ion-color-light);
        }
        ion-col.mini-lista{
            padding: 0px 8px;
        }
        .titulin {
            font-size: smaller; 
            text-transform: capitalize;
        }
</style>