import json2xls from  'json2xls';
import {reduce, pick, extend} from 'underscore'

const baseParsers = {
    'px': (x)=>{ return x},
    'ar': (x)=>{return x},
    //'l': (x)=>{return x},
    'pxn': (x)=>{return x}, 
    'e': (x)=>{return x}, 
    'en': (x)=>{return x}, 
    'fn': (x)=>{return x} 
}

const export2Xls = async function(datatable, parsers, nombre) {
    const parser = extend(baseParsers, parsers)
    const columnas = datatable.columns.reduce((m,v)=> {
        const k = v.props.field
        const t = v.props.header.replace(/\./g,'') 
        m[k]= t; return m 
    }, {})
    const cols = Object.keys(columnas)
    const valores = datatable.value.reduce( (m,v)=>{
        const vx = pick(v,cols)
        const v2 = reduce(vx,(m2,vv,kk)=> {
            m2[columnas[kk]] = parser[kk] == undefined ? parseInt(vv) : parser[kk](vv); 
            return m2
        } , {})
        m.push(v2)
        return  m
    },[])
    exportacionXLS(valores,nombre)
}



const exportacionXLS = async function(valores, nombre){
    const  xlsdata =  await json2xls(valores)
    const buf = new ArrayBuffer(xlsdata.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != xlsdata.length; ++i) view[i] = xlsdata.charCodeAt(i) & 0xFF;
    const blob = new Blob([buf], {
        type: 'application/octet-stream'
    });
    // Automatically download the file by appending an a element,
    // 'clicking' it, and removing the element
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob, {
        type: 'data:attachment/xlsx'
    });
    a.download = `${nombre}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
} 




export {
    export2Xls, exportacionXLS
}
