const dNumber = (n)=>{
    return n ===undefined ? 0 : n 
}     

var color1Base = '32,db,64'
var color2Base = 'eb,44,5a'

const gradiente_old  = ( weight ) => {
    const color1 = color1Base.split(',').map((x)=>parseInt(x,16))
    const color2 = color2Base.split(',').map((x)=>parseInt(x,16))
    var w1 = weight;
    var w2 = 1 - w1;
    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
    return '#'+rgb.map((x)=>x.toString(16).padStart(2,'0')).join('')
    
}

const gradiente = (w)=> {
    const colores = ["#f82525", "#f85a25", "#f88f25", "#f89d24", "#f8c425", "#f8f825", "#c4f825", "#8ff825", "#5af825", "#25f825", "#25f85a"]
    const i = Math.min(11,parseInt(w*10))
    return colores[i]
}


export {
 dNumber  , gradiente, gradiente_old
}

