<template>
   <ion-page :style="fontSize" >
        <CabeceraComponent :titulo="titulo_pag" color="darkdanger"/>
        <ion-content class="ion-padding" v-if="device" >  

            <ion-row class="ion-justify-content-left ion-align-items-top selectores" v-if="filtros">
                    <ion-col  size="24" size-sm="12" >
                        <div class="p-input-icon-right">
                            <InputText type="text" v-model="termino" placeholder="Buscar (min 4 letras)" @input="debounceSearch"/>
                            <i class="pi pi-search" />
                        </div>
                    </ion-col>
                    <ion-col size="24" size-sm="12" v-if="filtros['pdvs']">
                        <MultiSelect  v-model="SelectedFilters" :options="filtros['pdvs']" optionLabel="k" 
                            :filter="true"   
                            v-bind:key="device.tipo" 
                            
                            @change="filtrar"
                            placeholder="Filtrar pdvs" display="comma"/>
                    </ion-col>
                    
            </ion-row>

            <ion-row v-if="SelectedFilters">
                <ion-badge mode="ios" 
                    v-for="(zn,index) in SelectedFilters" 
                    color="darkdanger" v-bind:key="index"
                    @click="eliminar(index)">
                    {{zn.k}}
                        <ion-icon :icon="closeCircle" class="badge"></ion-icon>
                </ion-badge>
            </ion-row>
            
            <ion-row v-if="loading" class="spin ion-justify-content-center "  >
                <ion-col  v-if="loading" class="spin ion-align-self-center" size="12" > 
                    <ion-spinner color="darkdanger" v-if="loading"></ion-spinner>
                </ion-col>
            </ion-row>
                <Card v-if="!loading && datos.length==0">
                    <template #title>
                        No hay oportunidades
                    </template>
                    <template #content>
                        Actualmente no hay oportunidades activas para el usuario
                    </template>
                </Card>

                <DataTable :value="filtrados" :rows="filas" 
                    :paginator="paginador"
                    paginatorTemplate="RowsPerPageDropdown  FirstPageLink PrevPageLink  NextPageLink LastPageLink CurrentPageReport"
                    :rowsPerPageOptions="[10,25,50,100]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    v-if="!loading && datos.length>0"
                    class="p-datatable-oportunidades" 
                    v-bind:key="device.tipo && device.ancho"
                    scrollHeight="flex"
                    scrollDirection="vertical" 
                    :scrollable="true" 
                    removableSort
                    ref="datatable"
                    :rowClass="isNew"
                    @row-click="ShowDetail" 
                     >
                     <template #header>
                        <div class="table-header">
                            <ion-col size="44">
                                Oportunidades en tu area
                            </ion-col>
                            <ion-col>
                                <Button icon="pi pi-file-excel"  style="float:right !important;"
                                    @click="exportCSV($event)"
                                    class="p-button-rounded p-button-success" 
                                />
                            </ion-col>

                        </div>
                </template>
                <template #loading>
                    Cargando datos de stock. Por favor, espera.
                </template>

                    <Column v-for="(c, index) of columnas[device.tipo]" :field="c" 
                        :header="nombres[c]" :key="c + '_' + index" 
                        :sortable="paginador"
                        :headerStyle="anchos[c]" 
                        :bodyStyle="anchos[c]" 

                        >
                        <template #body="slotProps" >
                          <div :class="c=='cid'? 'derecha' : 'izquierda'" v-if="txtCols(c)">
                              {{slotProps.data[c]}}
                          </div>
                          <div class="izquierda" v-else-if="listCols(c)">
                               <span :innerHTML="listParser(slotProps.data[c],c)"></span>
                          </div>
                          <div class="izquierda" v-else-if="c =='pdv'"> 
                               {{device.tipo=='sm' ? slotProps.data[c] : pdvs_labels[slotProps.data[c]]}}
                          </div>
                          <div class="derecha" v-else-if="c =='eur'">
                               {{kEurParser(slotProps.data[c])}}
                          </div>
                           <div class="derecha" v-else-if="c =='last'">
                               {{now2Date(slotProps.data[c])}}
                          </div>
                          <div v-else class="derecha">
                              {{$filters.Entero(slotProps.data[c])}}
                          </div>
                        </template>
                    </Column>
                </DataTable>
           <!-- </ion-row>-->
        </ion-content>
        


    </ion-page>
</template>

<script>
    import { IonPage, IonContent,   IonRow,IonCol, IonBadge, IonIcon,
            IonSpinner, modalController
     } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import LeadsDetail from './LeadsDetail'

    import { defineComponent } from 'vue';
    import * as _ from 'underscore';
    import eventBus from "@/event-bus";
    import { Plugins } from '@capacitor/core';
    const { Storage } = Plugins;
    import { closeCircle } from 'ionicons/icons';
    import { useRoute } from 'vue-router';
    import { export2Xls } from '@/utilities/services/xls_export';
    export default defineComponent({
            name : "LeadsList",
            components: { 
                IonPage, IonContent, IonRow,IonCol, CabeceraComponent, IonBadge, IonIcon, 
                IonSpinner
            } , 
            computed :{
                fontSize () { 
                    window.fake_console('LeadsList',`--texto-size : ${12+(this.device.tamanyo)}px`)
                    return `--texto-size : ${12+(this.device.tamanyo)}px;`
                }, 
                anchos () {
                    const base = {
                            "sm" : {"pdv" : 10,"const" : 35,"nom" : 35,"eur" : 20},
                            "md" : {"pdv" : 20,"const" : 30,"nom" : 40,"eur" : 10},
                            "lg" : {"pdv" : 12,"const" : 20,"nom" : 25,"eur" : 10,"mails" : 22,"tels" : 11},
                            "xl" : {"pdv" : 10,"const" : 20,"nom" : 25,"eur" : 7,"cid" : 7,"mails" : 20,"tels" : 11}
                            }
                    /** Nos aseguramos que las definiciones de columnas sumen 100, sino aplicamos proporcionalidad  */
                    //const factores = _.reduce(base, (m,v,k)=> {m[k]=_.chain(v).values().reduce((mx,x)=>{return mx+=(x/100)},0).value() ;return m } , {}) 
                    const factor = _.reduce(_.values(base[this.device.tipo]), (m,v)=> {return m+=(v/100)},0)
                    window.fake_console(factor)
                    const resultado = _.reduce(base[this.device.tipo], (m,v,k)=> {m[k]= this.AnchoCol(v / factor) ;return m },{})
                    window.fake_console(this.device.tipo, this.device.ancho,  factor, resultado)
                    return resultado
                    },
            },
            data() {    
                return {
                    titulo_pag:"Listado de oportunidades vivas" , 
                    /** Iconos, se han de declara en data para poder ser utilizados  */
                    closeCircle, 
                    columnas :{
                        'sm':['pdv','const','nom','eur'],
                        'md':['pdv','const','nom','eur'],
                        'lg':['pdv','const','nom','eur','mails','tels'],
                        'xl' :['pdv','const','nom','eur','cid','mails','tels']
                    }, 
                    nombres: {
                        'pdv':'Pdv', 
                        'const':'Constructora', 'nom': 'Nombre proyecto', 
                        'desc': 'Descripcion', 'mails': 'Emails' , 'tels': 'Telefonos', 
                        'web': 'Web', 'eur':'Miles €', 'last': 'Actualizado', 
                        'cid': 'Id Construdata'
                    },
                    parsers: {
                        'pdv': x=>x, 
                        'const': x=>x, 'nom':  x=>x, 
                        'desc':  x=>x, 'mails': x=>x , 'tels':  x=>x, 
                        'web':  x=>x, 'eur': x=>parseInt(x/1000), 'last':  x=>x, 
                        'cid':  x=>x
                    },
                    
                   /** Variable de control para actuar en mount/update */
                    ruta: undefined , 
                    fetchUrl : undefined, 
                    filas: 10 , 
                    paginador : true, 

                    /** Variable que controla el spinner de carga  */
                    loading: true, 

                    /** variable de control del debounce (diferimiento ) */
                    debounce: null,

                    /**
                     * Variable donde cargamos los datos de las peticiones xhttp tal como vienen 
                     */
                    datos: undefined ,

                    /**
                     * Variable donde estan los datos que efectivamente se ven en la tabla 
                     * una vez aplicados todos los filtros 
                     */
                    filtrados: undefined, 
                    
                    /**  
                     *   Filtros posibles del usuario, obtenidos en la carga inicial, 
                     *   solo los que pueden tener sentido para el y con valores 
                     *   ya filtrados (sus pdvs, sus marcas.. ) 
                    */
                    filtros:undefined, 
                    

                    /**
                     * Variable que tiene todos los filtros posibles que aplicamos en la vista  
                     */
                    /** 
                     * Termino de busqueda que introducimos en el filtro libre 
                     */                    
                    termino: undefined, 

                    /** 
                     * Datos del dispositivo, son globales, controlamos los eventos de 'window' 
                     * para responder a los cambios de tamaño de ventana, orientacion...  
                     */                    
                    device: this.$device, 

                    /** Valores seleccionados para cada filtro, los posibles son los que estan en filters */
                    SelectedFilters: null, 
                    pdvs_labels :undefined
                }
            },  
            async updated(){
                window.fake_console('LeadsList','en el update 245')
                const route = useRoute();
                window.fake_console('LeadsList',this.ruta, route.path)
                if (route.fullPath!=this.ruta)
                {
                    this.loading = true;
                    window.fake_console('LeadsList','llamo al update ')
                    this.ruta = route.fullPath
                    this.procesoInicial()

                }         
                else {
                    window.fake_console('LeadsList','NO llamo al update')
                }       
            },
            async mounted() {
                this.loading = true;
                window.fake_console('LeadsList','en el mount 273')
                const route = useRoute();
                window.fake_console('LeadsList',this.ruta, route.path, route.query)
                this.ruta = route.fullPath
                window.fake_console('LeadsList',route.path, route.query)
                this.procesoInicial()

            }, 
            methods :{
                AnchoCol(x) { return `width: ${(this.device.ancho-32)*x/100}px !important`}, 
                /** Lo cambiaremos por un simple dialog, para ver 4 datos no hace falta más  */
               async ShowDetail(e) {
                /*this.$router.push(`/listado/sku_details?sku=${e.data.m}.${e.data.ar}`)*/
                /*window.fake_console(e)
                alert(e.data.desc)
               */
               const modal = await modalController
                    .create({
                        component: LeadsDetail,
                        cssClass: 'my-custom-class',
                        componentProps: {'dat': e.data},
                    })
                    eventBus().emitter.on('cerrar-modal-detalle', ()=>{
                        modal.dismiss()
                    })
                return modal.present();
               
               
               }, 
                isNew(data) {
                    return (data.last >(Date.now()+(7*24*3600))  )  ? 'resaltar_oportunidad' : null
                },
                txtCols(c) {
                    return ['const','nom','desc','web','cid'].indexOf(c)!=-1 
                },
                listCols(c){
                    return ['mails','tels'].indexOf(c)!=-1 
                },
                listParser(x, col) {
                    const separador = col=='mails'? ',' : '/'
                    if (x.length<5) { return '-'}
                    else {
                        const k = x.split(separador).map(y=>`<li>${y}</li>`).join(''); 
                        return `<ul>${k}</ul>` 
                   }                   
                },
                kEurParser(n) {
                    return [undefined, null, 0].indexOf(n)!=-1 ? '-' : parseInt(n/1000).toLocaleString('de-DE')
                },
                now2Date(n) {
                    return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Date(n).toISOString().replace(/-/g,'.').slice(0,10)
                },
                async procesoInicial() {
                    this.loading = true;
                    const lbs = await Storage.get({ key: 'stocks:labels:p' });
                    window.fake_console(lbs)
                    this.pdvs_labels= JSON.parse(lbs.value)

                    const fl =  await Storage.get({ key: 'proyectos:filtros' });
                    window.fake_console('LeadsList',fl)
                    this.filtros = JSON.parse(fl.value) 
                    window.fake_console('LeadsList',Date.now() +'filtros recuperados')
                    this.device = this.$device 
                    eventBus().emitter.on('device', (sz) => {  this.device=sz })          
                    /** este es el bloque de codigo que debe ejecutarse tanto si inicializamos como si actualizamos el componente  */
                    await this.mountFilters()
                    window.fake_console('LeadsList',Date.now() +'filtros montados ')
                    this.fetchData()
                    window.fake_console('LeadsList',Date.now() +'llamada a datos realizada ')
                }, 
                
                debounceSearch() {
                    clearTimeout(this.debounce)
                    this.debounce = setTimeout(() => { this.filtrar()}, 250)
                }, 
                /** monta los filtros que toquen en función de la vista */
                async mountFilters(){
                    window.fake_console('LeadsList',this.filtros)
                    this.SelectedFilters = []
                }, 
                async fetchData(){ 
                    this.loading = true
                    const url ='/oportunidades'
                    window.fake_console('LeadsList',url, this.fetchUrl)
                    if (url !=this.fetchUrl)
                    {
                        this.fetchUrl = url
                        const result = await this.$http.get(url) 
                        window.fake_console('LeadsList','Es cache?? : ',result.request.fromCache)
                        this.datos = result.data
                        window.fake_console(this.datos)
                        window.fake_console('LeadsList ',this.datos)
                    }
                    this.filtrar()
                    this.loading = false
                }, 

                eliminar(i){
                    this.SelectedFilters.splice(i,1)
                    this.filtrar()
                },
                
                
                async filtrar() {
                    window.fake_console('LeadsList',JSON.stringify(this.SelectedFilters))
                    window.fake_console(this.SelectedFilters)
                    /**nos quedamos solo con los 3 primeros digitos, no hay distincion fluids / resto */
                    const fl = this.SelectedFilters.map(e=>e.v[0].slice(0,3))
                    let filtrados = this.datos
                    if (fl.length!=0)
                        {
                            filtrados = _.filter(filtrados, (v)=> {return fl.indexOf(v['pdv'])!=-1 })
                        }
                    if (this.termino!=undefined && this.termino.length>=3){
                        filtrados = filtrados.filter((v)=> {return JSON.stringify(v).toLowerCase().indexOf(this.termino)!=-1})
                    }
                    const limite = this.limite!=undefined ? this.filas : filtrados.length
                    filtrados = _.sortBy(filtrados, (e)=>-e['eur']).slice(0,limite)
                    this.filtrados = filtrados
                    window.fake_console('LeadsList',this.filtrados.length, this.filas, this.paginador)
                    if (this.filtrados.length <=this.filas) { this.paginador = false}
                        else { this.paginador = true}
                    window.fake_console('LeadsList',this.filtrados.length, this.filas, this.paginador)
                    this.loading = false
                },
                                
                exportCSV() {
                 const nombre = this.titulo_pag.replace(/ /g,'.').replace(/[()]/g,'').toLowerCase()
                 export2Xls(this.$refs.datatable, this.parsers, nombre)
                }
            }
            
})
</script>

<style scoped>
    ion-row.spin{
        min-height: 300px;
    }
    spin {
        height: 75%
    }
    ion-spinner {
        width:75px; 
        height:75px;
        display: block;
        margin: auto;      
    }
    ion-badge {
        margin-top: 6px; 
        margin-right:6px; 
        padding-top: 0px;
        padding-right: 12px; 
        padding-left: 12px; 
        padding-bottom: 4px; 
    }
    ion-icon.badge {
        padding-top: 4px; 
        font-size:1.25rem; 
    }
    
    ion-row.selectores {
        border: 1px solid var(--ion-color-light);
    }
    .derecha {
        text-align: right !important;
        width: 100%;
    }
    .izquierda {
        text-align: left !important;
        
    }
    .p-card {
        width: 90%;
        margin : 20px auto;
    }
</style>

/** Todos los estilos relacionados con PrimeVue Datatable los declaramos como globales, 
    sino no  funcionan */
