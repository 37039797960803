<template>
    <ion-col size ="16">
        <ion-card >
            <ion-card-header v-if="titulo.length>0 && titulo!=undefined">
                <ion-card-subtitle size="small"  
                    v-if="titulo.length>0 && titulo!=undefined">
                {{titulo}}          
                </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content v-if="detalle.length>0 && detalle!=undefined">
                    <ion-row class="ion-justify-center knobs" v-if="knob_data">
                        <Knob :min="0" :max="maximo" :modelValue="yo" 
                            :size ="90" :showValue="true" class="inferior" 
                            :valueTemplate="desc"
                            :valueColor="color" 
                            />

                        <Knob :min="0" :max="maximo" :modelValue="ges"  
                            :size ="60"  :showValue="false" class="superior" />

                           <!---->
                    </ion-row>
                    <ion-row>
                    <ion-col class=" separata ion-float-right ion-text-end grande" v-if="nota">
                        {{detalle}}
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class=" separata ion-float-left ion-text-start" v-if="nota">
                        <ion-note class="ion-text-start cortado">
                            {{nota}} <small v-if="subnota"> {{subnota}} </small>
                        </ion-note>
                    </ion-col>
                </ion-row>    
            </ion-card-content>
        </ion-card>
    </ion-col>
</template>

<script>
    import {    IonCol,IonRow,
                IonCard, IonCardHeader, 
                IonCardSubtitle,
                IonCardContent, IonNote                
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import {gradiente} from '@/utilities/global_functions';
    export default defineComponent({
            name : "TarjetaPipelineKnob",
            components :  {    
                IonCol,IonCard, IonCardHeader, IonRow,
                IonCardSubtitle,
                IonCardContent, IonNote 
            }, 
            mounted() {
                window.fake_console('montada tarjeta', JSON.stringify(this.knob_data))
                window.fake_console( Object.values(this.knob_data).filter(Number) )
                this.maximo = Math.min(this.knob_data.target*2,Math.max(...Object.values(this.knob_data).filter(Number)))
                window.fake_console(this.maximo)
                this.yo =   this.knob_data.yo
                this.ges = this.knob_data.ges
                this.desc = `${this.knob_data.yo}${this.knob_data.desc}`
                window.fake_console(this.maximo, this.yo, this.ges)
                window.fake_console(this.yo/this.knob_data.target, Math.min(1,this.yo/this.knob_data.target))
                this.color = gradiente(Math.min(1,this.yo/this.knob_data.target))
                //window.fake_console(this.color)
            },
            data() {
                return {                    
                    maximo: undefined, 
                    ges:undefined, 
                    yo: undefined, 
                    color:undefined, 
                    desc : undefined 
                }
            },
            props: ['titulo','detalle','nota',  'knob_data', 'subnota']
    })
</script>

<style scoped>
    ion-row.knobs {
        min-height:80px;        
    }
    .p-knob {
        margin-left: 0.2em !important;
    }
    .superior {
        position:relative; 
        left:15px; 
        top:15px;
    }
    .inferior {
        position:absolute;
    }
       
    ion-note {
        font-size:0.9rem;
        width:100%;
        text-align: left;
    }
    ion-col { padding: 4px;}
    ion-col.grande {
        font-size:1.3rem;
    }
    ion-card {
        margin:6px  2px !important;
    }
    ion-card-content {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 4px; 
        padding-right: 4px;
        text-align: right; 
    }
    ion-card-subtitle{
        text-align: center;
    }
    ion-card-header {
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 4px ; 
        padding-bottom : 4px; 
    }
    ion-col.separata {
        padding-top: 2px  !important;
        padding-bottom: 2px  !important;
    }
</style>