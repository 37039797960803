<template>
  <ion-app>
    <ion-split-pane content-id="main-content" when="$device.ancho>1500">

    <!-- CONTENIDO NUEVO
    <Menu :usr="usuario"  />
    -->
    <Menu />
        <!-- Aqui incorporaremos el contenido de las navegaciones  -->
        <ion-content id="main-content" scroll-y ="true" >
          <router-view ></router-view>
        </ion-content>
    </ion-split-pane>
  </ion-app>

</template>

<script>
import { IonApp, IonContent , alertController, IonSplitPane
  } from '@ionic/vue';
import Menu from '@/components/Menu.vue'
import { defineComponent } from 'vue';
import eventBus from "@/event-bus";

export default defineComponent({
  name: 'App',
  data() {
    return {
      usuario: this.$current_user_name, 
      deferredPrompt: null, 
      intentos : 0 
    }
  },  
  components: {
    IonApp, Menu, IonContent, IonSplitPane
  }, 
   methods: {
    async openIosAlert() {
      const ancho_imagen = 1072
      const largo_imagen = 1775 
      const ancho_screen = window.screen.width
      const ancho = parseInt(ancho_screen*0.85)
      const alto = parseInt(largo_imagen*ancho/ancho_imagen)
      window.fake_console('app ',alto,ancho)
      const alerta = await alertController
        .create({
          cssClass: 'my-custom-class',
          message: `<img src="assets/tool-tip-install-ios.png" style="width:${ancho} !important; height:${alto} !important; border-radius: 2px">`,
          mode : "md",
          buttons: [
            {
              text: 'Ok',
              handler: () => { window.fake_console('app ','Confirm Okay')},
            },
          ]
        })
      return alerta.present();
    }, 
    async openInstallPromotion() {
      const toast = await alertController
        .create({
          header: 'Instala la aplicación ',
          message: 'Irá mucho más rápida y la tendrás accesible desde tu pantalla principal sin necesidad de entrar en Chrome.',
          position: 'middle',
          buttons: [
            {
              text: 'Si',
              //side: 'end',
              icon: 'checkmark-circle-sharp',
              handler: () => {
                window.fake_console('app ','Favorite clicked');
                this.intentos+=1
                this.install();
                toast.dismiss()
              }
            }, {
              text: 'No',
              //side: 'end',
              icon: 'close-circle-sharp',					
              role: 'cancel',
              handler: () => {
                this.dismiss()
                window.fake_console('app ','Cancel clicked');
              }
            }
          ]
        })
      return toast.present();
   },
    async dismiss() {
      window.fake_console('app ','El usuario no ha querido ')
      this.deferredPrompt = null;
    },
    async install() {
      window.fake_console('app ','Instalando.... ')
      this.deferredPrompt.prompt();
    }, 
    getDevice() {
      const w = window.innerWidth 
      const h = window.innerHeight 
      return { 'ancho': w , 'largo' : h , 
       'tamanyo': w < 576 ? 1 : w < 900 ? 3 : w < 1200 ? 5 : 5, 
        'tipo' : w < 576 ? 'sm' : w < 900 ? 'md' : w < 1200 ? 'lg' : 'xl'}
     }
  },

  mounted() {
    /* Deteccion de IOS y propuesta de que el usuario siga pasos de instalación  */
      // Detects if device is on iOS 
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
      }
      // Detects if device is in standalone mode
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.openIosAlert()
      }
    // Detecta cambios de ancho
      window.addEventListener('resize', () => {
          eventBus().emitter.emit('device',this.getDevice());
        })

    // Inicialización del tamaño de pantalla y tipo de dispositivo 
        eventBus().emitter.emit('device',this.getDevice());
  }, 
  
  created(){
      /** Control para instalar la app en android  */
      window.addEventListener("beforeinstallprompt", e => {
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          if (this.intentos==0) {this.openInstallPromotion()}
        });
      window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
        });
  }
});
</script>
<style lang="scss" >
  ion-icon {
        &[class*="custom-"] {
        mask-size: contain;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        background: currentColor;
        width: 1em;
        height: 1em;
      }

      &[class*="custom-dart-board"] {
        mask-image: url(../public/assets/dart-board-red.svg);
      }
  }


  html {
    font-size: var(--texto-size) !important; 
  }
  .my-custom-class .alert-message{
    max-height: 700px !important;
  }
  ion-toolbar > ion-title {
    padding-left: 48px !important; 
  }
  .my-custom-class .alert-wrapper {
    --max-width: 90%;
    --width: 90%;
    --max-height: 80%;
    --height: 80%;
    }
    span.p-sortable-column-icon.pi.pi-fw.pi-sort-alt{
        display: none
    }
    div.table-header {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem; 
        text-align: left;
        font-size: 1.3rem;
    }
    table > * { font-size: var(--texto-size)  !important;  }
    div.p-paginator > * { font-size: var(--texto-size)  !important; }
    .p-inputext { font-size: var(--texto-size)  !important; }
    .p-datatable { min-height:300px}
    ::v-deep(.p-datatable.p-datatable-oportunidades) {
       .p-datatable-flex-scrollable {
           height: auto;
       }
       .p-datatable-flex-scrollable .p-datatable-wrapper {
           flex: 0 1 auto !important;
       }
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 3rem;
        }
        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead > tr > th {
            text-align: center;
        }
        .p-datatable-tbody > tr > td {
            cursor: auto;
        }
        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
        div.p-dropdown {
            max-height: 32px !important;
        }
        .p-datatable .p-datatable-header ,  .p-datatable .p-datatable-tbody {
            padding: 0.43rem !important; 
        }   

        .p-datatable-scrollable .p-datatable-thead > tr > th,
        .p-datatable-scrollable .p-datatable-tbody > tr > td,
        .p-datatable-scrollable .p-datatable-tfoot > tr > td {
            display: flex !important;
            flex: 0 1 auto !important;
            align-items: center !important;
        }
    }
    .p-datatable-scrollable .p-datatable-thead > tr > th,
    .p-datatable-scrollable .p-datatable-tbody > tr > td,
    .p-datatable-scrollable .p-datatable-tfoot > tr > td {
        display: flex !important;
        flex: 0 1 auto !important;
        align-items: center !important;
    }
    div .p-dropdown { max-height: 32px !important; }
    div .p-dropdown> span.p-dropdown-label { padding-top : 7px; padding-bottom: 8px; }
    
    div .p-column-title {
        white-space: pre-wrap;
        text-align: center;
    }
    .p-multiselect{
        width:100%;
    }
    div.p-dropdown.selector {
        width:100% !important;
        padding-top: 3.5px !important; 
        padding-bottom: 2.5px !important; 
        max-height: 39px !important; 
        background-color: var(--ion-color-light);
        font-weight : bold !important;
        color: var(--ion-color-danger) !important;
    }
    div.p-input-icon-right{
        width:100%  !important;
    }
    input.p-inputtext.p-component{
        width:100% !important;
    }
    td, th {
        padding: 0.3rem !important;
        
      }    
    Button.p-button-rounded{
        color: var(--ion-color-darksuccess);
        font-size: 1.75rem;
        font-weight: bold;
        float:right; 
    } 
    tr.resaltar_oportunidad {
        background-color: var(--color-resaltado-medio) !important;  
    }
     tr.resaltar_proyecto {
        background-color: var(--color-resaltado-medio) !important;  
    }

    p-dialog {        
        width: 90%;
        max-width: 400 px;
        height: 90%;
        max-height: 600px;
    }
    ion-note.centrar {
      
      width:100% !important; 
      padding-right: 0px !important; 
      padding-left: 0px !important; 
      text-align: center !important;

    }
</style>