/** 
     * NOTA GENERAL IMPORTANTE: 
     *      El esquema de control de delays es así xk los datos se actualizan una vez al día (cada mañana alrededor de las 7 de la mañana)
     *      por lo que no tiene sentido descargar multiples veces datos que persisten un dia y son en si mismos pesados. 
     *      Esta estrategia la aplicamos a los resumenes, que son los datos que se piden con más frecuenciaç
     *      No a los detalles, que se gestionan directamente a través de la configuración de cache de axios, 
     *      que aplica de forma general a cualquier peticion xhttp y esta configurada en main.js 
     * 
 */

/** 
    * Funciones de caracter global utilizadas al inicializar la aplicación 
 */

    import { Plugins } from '@capacitor/core';
    const { Storage } = Plugins;
    import axios from 'axios';



/**
    * Delay de tiempo que aplicamos para volver a pedir algo al servidor desde la última vez que los hicimos 
    * Aplica a los datos para los que grabamos marca de tiempo en Stoarge (los que tienen stamp)
    * Siempre hay una funcion getXX (comprueba tiempos, si no ha pasado el delay coge los datos de storage) 
    * fetchXX (hace la decarga y procesa los datos)
*/
import { DELAY as delay } from '../global_constants';

 
   /* Recuperamos las últimas fechas de actualización */
   const setInitDates = async function() {
       let lUpdate = await axios.get('/last_update')
       window.fake_console('init_services ',lUpdate.data)
       await Storage.set({ key: 'proyectos:last_update', value: JSON.stringify(lUpdate.data)});
   }

   /** Verificamos la antiguedad de los filtros, si exceden el delay los pedimos de nuevo al servidor  */
   const getInitFilters = async function() {
    const filters_ts =  await Storage.get({ key: 'proyectos:filtros:last_update' });
    const filters_flags = JSON.parse(filters_ts.value)
    window.fake_console('init_services ','F106 :'+JSON.stringify(filters_flags))
    if ( filters_flags==undefined ||( filters_flags.stamp< Date.now()-delay)) 
        {
        /** No descargamos, los datos son recientes, tomamos memoria */
        window.fake_console('init_services ','Descargo los filtros.......')
        await fetchInitFilters()
        }
    else  
        {
        window.fake_console('init_services ','No hacemo nada, los datos son correctos ')
        }
    }

    /** Los filtros son comunes para toda la aplicación , se basan en : 
     * 
     * Peticion 'filtros'
     * Ejes que puede ver el usuario (vienen ya implicitos en la respuesta de filtros, si no puede verlo no viene )
     * Peticion 'elements'
     * Datos concretos que forman parte de su scope (en base a los dato de stock diario)
     * Una vez obtenidos ambos, buscamos la interseccion y así todos los selectores tendrán solo los filtros que tienen sentido para el usuario 
     */


   const fetchInitFilters = async function ()  {
        /**  EN ESTE CASO NO FILTRAREMOS AQUI, 
         *   LAS PETICIONES DE PIPELINE INCLUYEN LAS FACETAS QUE APLICAN 
         *   en los campos p (array de pdvs), m (array de marcas), e (array de exteriores)
         *   Pasamos es filtro en el servicio que recupera el pipeline, ajustando así los filtros 
         */
         /** Cargamos en memoria los filtros, solo recupera los que tienen sentido para el usuario  */
        let flData = await axios.get('/filtros') 
        const filtered_flData = flData.data
        window.fake_console('init_services ','Es cache?? : ',flData.request.fromCache)
        window.fake_console('init_services ',  filtered_flData)
        window.fake_console('init_services ',  filtered_flData)
        await Storage.set({ key: 'proyectos:filtros', 
                value: JSON.stringify(filtered_flData)});
                window.fake_console('init_services ','FILTROS CARGADOS!!!!!!!!!!!!!')
        /** Cargamos en memoria los filtros y  */
        window.fake_console('init_services ',filtered_flData)
        flData = await axios.get('/labels') 
        window.fake_console('init_services ','Es cache?? : ',flData.request.fromCache)
        window.fake_console('init_services ',flData.data)
        window.fake_console('init_services ',Object.keys(flData.data))
        Object.keys(flData.data).forEach(async function(k){
            await Storage.set({ key: `proyectos:labels:${k}`, value: JSON.stringify(flData.data[k])});
            await Storage.set({ key: 'proyectos:filtros:last_update', value: JSON.stringify({'stamp': Date.now()}) });
        })
        flData = await axios.get('/network')
        await Storage.set({ key: `proyectos:network`, value: JSON.stringify(flData.data)});
   }
export {
    setInitDates, getInitFilters, fetchInitFilters

}
