<template>
  <ion-page>      
        <CabeceraComponent 
            titulo="Editar Proyecto" 
        />
        <ion-content class="ion-padding">
                <div class="crear">
                    <ion-toolbar>
                        <ion-title class="completo">
                            <small>Nombre de la partida del proyecto</small>    
                        </ion-title>
                    </ion-toolbar> 
                        <div class="card">
                            <div class="p-grid">
                                <div class="p-field p-col-12">
                                    <div class="p-col">
                                        <InputText v-model="bk_name" id="obname" :disabled="not_editable"
                                            type="text" placeholder="Nombre de la nueva partida del proyecto, minimo 10 letras" 
                                            :class="(bk_name!=undefined && bk_name.length<10) ? 'p-invalid' : ''"
                                            />
                                        <label class="etiqueta-error" for="obname" v-if="(bk_name!=undefined && bk_name.length<10)" >Como minimo 10 caracteres</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="elegir" v-if="bk_name">
                    <ion-toolbar>
                            <ion-title class="completo">
                                 <small>Elige la marca principal</small>    
                                 </ion-title>
                    </ion-toolbar> 
                    <DropDown v-model="marca_sel" :options="marcas" optionLabel="k" class="selector" 
                        :filter="true"   v-bind:key="'sel_brand'"  
                        @hide="filtra_pline"
                        dataKey="_id"
                        optionValue="_id"
                        :disabled="not_editable"
                        placeholder="Elige marca «ganadora»" />
                </div>

                <div class="elegir" v-if="filteredPlines">
                <ion-toolbar>
                        <ion-title class="completo">
                            <small>Selecciona un Product Line </small>   
                        </ion-title> 
                </ion-toolbar> 
                    <DropDown v-model="pline_sel" :options="filteredPlines" optionLabel="k" class="selector"
                        :filter="true"   v-bind:key="'sel_pline'"  
                        @hide="selectPline($event)"
                        dataKey="_id"
                        optionValue="_id"    
                        :disabled="not_editable"                   
                        placeholder="Elige la pline principal de la partida" />
                </div>

                <div class="elegir" v-if="marcas_alternativas">
                <ion-toolbar>
                    <ion-title class="completo"><small>Detalla marcas alternativas</small></ion-title>
                </ion-toolbar> 
                        <MultiSelect  v-model="marcas_alt_sel" :options="marcas_alternativas" optionLabel="k" 
                            :filter="true"
                            dataKey="_id"
                            optionValue="_id"  
                            :disabled="not_editable"
                            v-bind:key="'marcas_alternativas_selector'" 
                            placeholder="Seleccionar alternativas" display="comma"
                        />
                </div>


                <div class="elegir" v-if="marcas_alt_sel">
                    <ion-toolbar>
                        <ion-title class="completo">
                            <small>Oferta principal G2K</small>    
                            <ion-badge color="danger" style="float:right" 
                                @click='follow'
                                v-if="oferta_sel!=undefined && oferta_sel!='sin_oferta'">
                                <small>Ver en Rac</small>
                            </ion-badge>
                        </ion-title>
                    </ion-toolbar> 
                    <DropDown v-model="oferta_sel" :options="ofertas" optionLabel="tx" class="selector"
                        :filter="true"   v-bind:key="'sel_oferta'"  
                        dataKey="_id"
                        optionValue="_id"
                        :disabled="not_editable"
                        placeholder="Elige oferta" />
                </div>


                <ion-row v-if="marcas_alt_sel" class="elegir" >
                    <ion-col size="26">
                        <ion-toolbar>
                            <ion-title class="completo"><small>Venta potencial?</small></ion-title> 
                        </ion-toolbar> 
                        <ion-note color="danger"><small>Importe en € sin decimales</small></ion-note>
                            <InputNumber  
                                mode="currency" locale="de-DE" currency="EUR"  
                                :maxFractionDigits="0" 
                                showButtons buttonLayout="horizontal"
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" 
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" 
                                :step="1000" :min="5000" :max="3000000"
                                :disabled="not_editable"
                                placeholder="Minimo 5.000€"
                                v-model="venta"
                            />
                        <ion-note  style="font-weight:500 !important;"   color="danger" v-if="venta<5000 || venta>3000000">El importe debe estar entre 5.000 y 3 Millones de Euros.</ion-note>
                    </ion-col>
                        
                    <ion-col size="22">
                        <ion-toolbar>
                            <ion-title class="completo"><small>Fecha revision</small></ion-title>
                        </ion-toolbar> 
                        <ion-note color="danger"><small>Se borrará 30 días después</small></ion-note>
                        <Calendar  v-model="review_date" dateFormat="dd.mm.yy" :disabled="not_editable"/> 
                    </ion-col>
                </ion-row>
        </ion-content>
        <ion-footer color="primary">
                <ion-row style="margin-top: 16px; margin-bottom:16px;">
                    <ion-col size="24">
                        <ion-button class ="derecha" color="danger" @click="BorrarProyecto"  v-if="!not_editable">Borrar</ion-button>
                    </ion-col>
                    <ion-col size="24">
                        <ion-button class="izquierda" color="primary" @click="GrabarProyecto" :disabled="!Validar"  size="small" v-if="!not_editable">Actualizar</ion-button>
                    </ion-col>
                </ion-row>
        </ion-footer>





       
    </ion-page>
</template>


<script>
    import { IonContent, IonPage,IonButton,IonCol,IonNote, 
            IonToolbar,IonTitle, IonRow, alertController, IonBadge, IonFooter 
        } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import {intersection, reduce, omit, keys, extend , map} from 'underscore'; 
    import {initcap} from '@/utilities/filters.js';
    import { Plugins } from '@capacitor/core';
    const { Storage } = Plugins;
    export default defineComponent({
        name: 'EditProject',
        components: {   
            IonContent, IonPage,IonButton,IonCol,IonRow, IonBadge, IonNote, 
            IonToolbar,IonTitle, IonFooter , CabeceraComponent
            }, 
        props :['project_data'] ,
                computed: {
                Validar : function(){
                    window.fake_console([this.bk_name, this.venta  , this.review_date ,this.marca_sel , this.pline_sel , this.marcas_alt_sel ,this.oferta_sel])
                    const x = [
                        this.bk_name, 
                        this.venta>=5000 && this.venta<=3000000, 
                        this.venta  , 
                        (this.review_date == undefined || typeof(this.review_date)!='object') ? undefined : true, 
                        this.marca_sel , 
                        this.pline_sel , 
                        this.marcas_alt_sel==undefined ? undefined : this.marcas_alt_sel.length == 0 ? undefined : true , 
                        this.oferta_sel 
                    ]
                    window.fake_console(x)
                    return x.every(y=>y!=undefined)
                }
        },
        data() {
            return {
                not_editable: false,
                loading :true, 
                edit_main_brand: false, 
                bk_name: undefined, 
                marcas : undefined, 
                plines: undefined , 
                marcas_pline: undefined,
                marca_sel : undefined , 
                filteredPlines : undefined, 
                pline_sel: undefined, 
                oferta_sel: undefined, 
                marcas_alternativas : undefined, 
                marcas_alt_sel : undefined ,
                review_date: undefined, 
                venta: undefined, 
                proyecto : undefined 
            }
        }, 
        async mounted() {
            /** Recuperamos las marcas posibles y las relaciones entre marcas y plines */
            const proyecto = JSON.parse(this.project_data)
            this.proyecto = proyecto
            this.not_editable = !(this.$editor && this.$current_user === this.proyecto.e)
            const fl =  await Storage.get({ key: 'proyectos:filtros' });
            const filtros = JSON.parse(fl.value) 
            await this.getOfertas()
            this.marcas = map(filtros.marcas, (x)=> {x['_id']=x['v'][0]; return x })
            const mpl =  await Storage.get({ key: 'proyectos:marcas_pline' });
            const d = JSON.parse(mpl.value)
            window.fake_console(d)
            this.plines = map(d.plines, (x)=> {x['_id']=x['l'][0]; return x })
            this.marcas_pline = d.marcas_pline
            this.bk_name = proyecto.bk
            this.venta = proyecto.v
            this.review_date = new Date(proyecto.t)
            this.marca_sel = proyecto.mp  
            this.filteredPlines = this.plines.filter( (p)=> {
                return intersection(p.l, this.marcas_pline[this.marca_sel]).length>0 ||p.l[0] ==  proyecto.l
            })
            this.pline_sel = proyecto.l
            this.oferta_sel = proyecto.of===undefined ? 'sin_oferta' : proyecto.of
            const malt =  reduce(this.marcas_pline, (m,v,f)=> {
                if ( v.indexOf(proyecto.l) !=-1 && f!=proyecto.mp ) {m.push(f)}
                return m;
                },[]);
            this.marcas_alternativas = this.marcas.filter((v)=> {return intersection(v.v, malt).length>0 ||proyecto.m.indexOf(v._id)!=-1})
            this.marcas_alt_sel = proyecto.ma
            this.loading = false
        },
        methods : {
            follow(){
              const of = this.oferta_sel.replace(/\.\d{2}\./,'.')
              window.fake_console(this.oferta_sel, of)
              //window.open(`https://gae.grupoelectrostocks.com/ofertas/#/oferta/${of}`, "_blank");
              this.getBlob(`/presupuesto/${of}`)
            }, 
            getBlob(url, payload) {
              const newPayload = (payload) ? Object.assign(payload, { responseType: 'blob' }) : { responseType: 'blob' };
              this.$http.post(url, newPayload)
                .then((response) => {
                  // Crear un objeto Blob directamente con los datos binarios
                  const blob = new Blob([response.data], { type: 'application/pdf' });
                  // Crear una URL para el Blob
                  const data = URL.createObjectURL(blob);

                  window.open(data, '_blank');
                })
                .catch((e) => {
                  console.error('Error', e);
                });
            },
            async getOfertas() {
                window.fake_console(this.project_data)
                window.fake_console(`/ofertas/${this.project_data.c}`)
                const ofd = await this.$http(`/ofertas/${this.proyecto.c}`)
                this.ofertas = map(ofd.data , (o)=> {
                        o._id = `${o.p}.${o.s}.${o.np}`; 
                        o.tx = `${o.p}.${o.np} - ${o.pc != undefined ? initcap(o.pc) : o.n+' (No detallado)' }`; 
                        return o;
                    
                    }) 
                this.ofertas.unshift({'_id': 'sin_oferta', 'tx': 'No hay oferta'})
                window.fake_console(this.ofertas)   
            }, 
            filtra_pline(){
                /** Paso posterior a la selección de la marca principal */
                this.filteredPlines = undefined 
                this.marcas_alternativas = undefined
                this.marcas_alt_sel = undefined 
                this.pline_sel = undefined 
                this.filteredPlines = this.plines.filter( (p)=> {
                    return intersection(p.l, this.marcas_pline[this.marca_sel]).length>0
                })
                /** Si no es significativa marcamos como sin categoria, 
                 *  en el siguiente paso dejaremos elegir cualquier marca  */
                if (this.filteredPlines.length==0) {
                    this.filteredPlines= this.plines.filter(p=>p.l[0]=='9900A')
                    this.pline_sel = '9900A'
                    this.selectPline()   
                }
            },
            selectPline() {
                this.marcas_alternativas= undefined 
                this.marcas_alt_sel = undefined
                const pline = this.pline_sel;
                const marca = this.marca_sel;
                window.fake_console(pline, marca, this.marcas_pline)
                let malt
                if (this.filteredPlines.length == 1 && this.filteredPlines[0]['_id']=='9900A')
                {
                    window.fake_console('Estoy en el caso de marca unica')
                    /** Si es una marca 'menor' y no hay pline, dejamos ver todas la marcas  */
                    window.fake_console(this.marcas_pline, marca)
                    malt = keys(omit(this.marcas_pline, marca))
                    window.fake_console(malt)
                }
                else 
                {
                    malt = reduce(this.marcas_pline, (m,v,f)=> {
                    if ( v.indexOf(pline) !=-1 && f!=marca ) {m.push(f)}
                    return m;
                    },[]);
                }
                this.marcas_alternativas = this.marcas.filter((v)=> {return intersection(v.v, malt).length>0 })
                
            },
            async GrabarProyecto(){
                window.fake_console(this.proyecto)
                const pro = {
                    bk: this.bk_name, 
                    v: this.venta, 
                    t : this.review_date.getTime(), 
                    l : this.pline_sel , 
                    mp : this.marca_sel, 
                    ma :  this.marcas_alt_sel, 
                    of: this.oferta_sel
                }
                window.fake_console(pro)
                window.fake_console(
                    extend(this.proyecto, pro)
                )
                const post = await this.$http.post('/proyecto',{'proyecto': extend(this.proyecto, pro)})
                    if (post.data.status =='error') {
                        alert('Algo no hay ido bien, revisa los datos...')
                    }
                    else {
                        
                        const alerta = await alertController
                            .create({
                                    
                                    message: 'Nuevo proyecto informado',
                                    mode : "md",
                                    buttons: [
                                        {
                                        text: 'Salir',
                                        handler: () => { this.$router.replace('/resumen/pipeline')},
                                        },
                                    ]
                            })
                            return alerta.present();     

                    }
            },
        async BorrarProyecto(){
            window.fake_console(this.proyecto)
            const _id = this.proyecto._id
            const alerta = await alertController
                .create({
                        header: 'Borrar proyecto', 
                        message: 'Seguro que quieres borrar este proyecto??',
                        mode : "md",
                        buttons: [
                            {
                            text: 'No'
                            },
                            {
                            text: 'Si',
                            handler: async () => { 
                                try {
                                    const borrado = await this.$http.delete(`/proyecto/proyectos:obra:${_id}`)
                                    window.fake_console(borrado.data)
                                    this.$router.go(-1)
                                }
                                catch (ex) {
                                    alert('No hemos podido borrar el proyecto')
                                }
                                },
                            },
                        ]
                })
                return alerta.present();     
            }
        },

        });
</script>

<style scoped>
    .etiqueta-error {
        color:var(--ion-color-darkdanger);
        font-style:italic;
        padding-left: 1rem;
    }
    ion-row{ width: 100%; }
    ion-col{ padding: 2px !important;  }
    
    ion-badge {
        margin-top: 6px; 
        margin-right:6px; 
        padding-top: 0px;
        padding-right: 12px; 
        padding-left: 12px; 
        padding-bottom: 4px; 
    }
    ion-toolbar {
        --min-height: 36px;
    }
    ion-button{
        width: 8rem; 
        height: 2.2rem;
        --border-radius: 6px;

    }
    ion-button.derecha {
        float:right; 
        margin-right: 2rem;
    }
    ion-button.izquierda {
        float:left; 
        margin-left: 2rem;
    }
    div.crear, div.elegir, ion-row.elegir {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
        padding-top: 0px;
        border: 1px solid var(--ion-color-light);
        margin-top:8px;
    }
    ion-title.completo {
        padding-left: 16px; 
        padding-right: 16px; 
        text-align: left;
    }
    .p-inputnumber {
        width: 100% !important;
    }
    .p-inputnumber-input{
        text-align: right;
    }
</style>
