<template>
   <ion-page  >
        <CabeceraComponent 
            titulo="Actualizacion de Oportunidades" 
            color="darkdanger"
        />
             <ion-content class="ion-padding" >  

            <ion-row class="ion-justify-content-left ion-align-items-top selectores">
                <ion-col  size="24" size-sm="12" >
                
                    <FileUpload mode="basic"  
                        name="demo[]" :multiple="false" accept=".xls,.xlsx" 
                        :customUpload="true" 
                        :auto="true" @uploader="myUploader($event)"  
                        :fileLimit="1"
                        chooseLabel="Selecciona un archivo xls o xlsx"  
                        color="primary"
                        />

                
                </ion-col>
                        

            </ion-row>
 
        </ion-content>    
            
    </ion-page> 
</template>

<script>
    import {    IonPage, IonContent, IonRow, IonCol, alertController
                } from '@ionic/vue';
    import CabeceraComponent from '@/components/CabeceraComponent'
    import { defineComponent } from 'vue';
    import axios from 'axios';
    import XLSX from 'xlsx';
    export default defineComponent({
            name : "LeadsUploader",
            components: {  
                IonPage, IonContent,   IonRow, IonCol,
                CabeceraComponent,
                },
            mounted() {
                window.fake_console('en el mount')
            },
            methods : {
                async Alerta(precheck, xlsData) {
                   const mensaje = `
                    <h2>Actualizacion obras construdata</h2>
                    <p>Antes de que actualices hay ${precheck.ant} obraas. Si confirmas : </p>
                    <ul>
                        <li>Actualizarás: ${precheck.rU.n}</li>
                        <li>Eliminarás: ${precheck.rD.n}</li>
                        <li>Insertarás  ${precheck.rN.n} nuevos</li>
                    </ul>
                    <p>Quieres continuar...</p>
                    `
                   const alerta = await alertController
                    .create({
                            cssClass: 'my-custom-class',
                            message: mensaje,
                            mode : "ios",
                            buttons: [
                                {
                                text: 'Si',
                                handler: async () => { 
                                    
                                    window.fake_console('Realizamos la actualización')
                                    const actualizar = await axios.post('/oportunidades',xlsData)
                                    if (actualizar.data.status =='error'){
                                         alert('Algo no hay ido bien, revisa que el fichero tenga la estructura correcta...')
                                    }
                                    else {
                                        alert('Actualización correcta')
                                        this.$router.replace('/resumen/pipeline')
                                        } 
                                    
                                },
                                },
                                {
                                text: 'No',
                                handler: () => { window.fake_console('Confirm Okay')},
                                },
                            ]
                    })
                    return alerta.present();         
                }, 
                async myUploader(e){
                    const self = this;
                    window.fake_console(e.target)
                    var files = e.files[0];
                    window.fake_console(files)
                    var reader = new FileReader();
                    reader.onload = async function(e) {
                        var data = new Uint8Array(e.target.result);
                        var workbook = XLSX.read(data, {type: 'array'});
                        window.fake_console(workbook)
                        const hoja1 = workbook.SheetNames[0]
                        const xlsData = {'construdata': XLSX.utils.sheet_to_row_object_array(workbook.Sheets[hoja1])}
                        window.fake_console(XLSX.utils.sheet_to_row_object_array(workbook.Sheets[hoja1]))
                        /** Hacemos un check antes de subir datos  */
                        const post = await axios.post('/precheck_oportunidades', xlsData, {maxContentLength: 100000000,maxBodyLength: 1000000000})   
                        if (post.data.status =='error') {
                            alert('Algo no hay ido bien, revisa el fichero...')
                        }
                        else {
                                self.Alerta(post.data, xlsData)
                        }
                        
                    };
                    reader.readAsArrayBuffer(files);
                }
            },
            data () {
                return {
                }
            }, 
})
</script>

<style scoped>

  .my-custom-class .alert-message{
    max-height: 700px !important;
    text-align: left;
  }
  
  .my-custom-class .alert-wrapper {
    --max-width: 90%;
    --max-height: 80%;
    text-align: left;
    }
  

</style>